import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";
import "./HrAccounting.css";
import HrAccountingMenu from "./HrAccountingMenu";
import HrAccountingBenefitListWrapper from "./hrAccountingList/HrAccountingBenefitListWrapper";

export const HrAccountingBenefitWrapper = () => {
  return (
    <>
      <div className="w3-col" style={{ minHeight: "400px" }}>
        <div className="w3-col" style={{ marginTop: "20px" }}>
          <div className="w3-container">
            <div className="w3-content">
              <div className="w3-col w3-margin-bottom">
              <Breadcrumb
                    items={[
                      {
                        title: (
                          <span className="siteBorderPrimaryColor w3-large">
                            <Link to={"/hr"}>HRCLOUD</Link>
                          </span>
                        ),
                      },
                      {
                        title: (
                          <span className="siteBorderPrimaryColor w3-large">
                          Benefit Management
                          </span>
                        ),
                      },
                    ]}
                  />
                <br />
                <p>
                  Borem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                  vulputate libero et velit interdum, ac aliquet odio mattis.
                  <br />
                  Class aptent taciti sociosqu ad litora torquent per conubia
                  nostra, per inceptos himenaeos.
                </p>
              </div>

              <HrAccountingMenu></HrAccountingMenu>
              <HrAccountingBenefitListWrapper></HrAccountingBenefitListWrapper>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HrAccountingBenefitWrapper;
