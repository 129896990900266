import { Modal } from "antd";
import { useState } from "react";
import { appZIndex } from "../../../utils/appconst";
import { HrEmployeeEditContractInfoForm } from "./hrEmployeeforms/HrEmployee-Edit-Contract-Info-form";
import "./hrEmployeeManagementComp.css";

type IHrEmployeeDetailCardEmploymentHistory = {
  employeeManagementData?: any | null;
  refreshPage?: () => void;
};

export const HrEmployeeDetailCardEmploymentHistory: React.FC<
  IHrEmployeeDetailCardEmploymentHistory
> = ({ employeeManagementData, refreshPage }) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCancelModal = () => {
    setShowModal(false);
  };
  return (
    <>
      <div className="w3-col" style={{ minHeight: "100px" }}>
        {/* Contract Information  */}
        <div className="grid mb-12 pb-8  pt-4 border-t-2">
          {/* Section Header */}
          <div className="grid grid-cols-2 gap-1">
            <div>
              <span className="text-black text-md font-sans font-medium">
                Employement History 1
              </span>
            </div>
            <div className="flex items-end justify-end">
              <button
                onClick={handleShowModal}
                className="text-black text-xs border border-black rounded-md font-sans h-8 px-2 py-1.5 pe-2"
              >
                Edit
              </button>
            </div>
          </div>

          {/* Section Body */}
          <div className="grid grid-cols-1 gap-1 gap-y-8 mt-4 md:grid-cols-2 lg:grid-cols-3">
            {/* Contract Type   */}
            <div>
              <div>
                <div className="pb-4 text-black text-sm font-sans font-medium">
                  Employment Type
                </div>
                <div className="text-black text-xs font-sans">Full-Time</div>
              </div>
            </div>

            {/* Location Type   */}
            <div>
              <div>
                <div className="pb-4 text-black text-sm font-sans font-medium">
                  Location Type
                </div>
                <div className="text-black text-xs font-sans">Remote</div>
              </div>
            </div>

            {/* Current Department  */}
            <div>
              <div>
                <div className="pb-4 text-black text-sm font-sans font-medium">
                  Department
                </div>
                <div className="text-black text-xs font-sans">
                  {" "}
                  Frontend Dev{" "}
                </div>
              </div>
            </div>

            {/* Position   */}
            <div>
              <div>
                <div className="pb-4 text-black text-sm font-sans font-medium">
                  Position
                </div>
                <div className="text-black text-xs font-sans">Manager</div>
              </div>
            </div>

            {/* Job Title  */}
            <div>
              <div>
                <div className="pb-4 text-black text-sm font-sans font-medium">
                  Job Title
                </div>
                <div className="text-black text-xs font-sans">
                  Senior Developer
                </div>
              </div>
            </div>

            {/* Period */}
            <div>
              <div>
                <div className="pb-4 text-black text-sm font-sans font-medium">
                  Period
                </div>
                <div className="text-black text-xs font-sans"> 2 Years </div>
              </div>
            </div>

            {/* Start Date  */}
            <div>
              <div>
                <div className="pb-4 text-black text-sm font-sans font-medium">
                  Start Date
                </div>
                <div className="text-black text-xs font-sans">
                  {" "}
                  01 Jan 2024{" "}
                </div>
              </div>
            </div>

            {/* End Date  */}
            <div>
              <div>
                <div className="pb-4 text-black text-sm font-sans font-medium">
                  End Date
                </div>
                <div className="text-black text-xs font-sans"> ---- </div>
              </div>
            </div>

            {/* Number Of Promotion   */}
            {/* <div>
              <div>
                <div className="pb-4 text-black text-sm font-sans font-medium">
                  Number of Promotion
                </div>
                <div className="text-black text-xs font-sans"> 4 </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      {/* Modal */}
      <Modal
        zIndex={appZIndex.modal}
        open={showModal}
        title={"Update Employment Information"}
        onCancel={handleCancelModal}
        width={700}
        footer={[<p style={{ minHeight: "00px" }}></p>]}
      >
        <div style={{ maxHeight: "400px", overflow: "scroll" }}>
          <HrEmployeeEditContractInfoForm></HrEmployeeEditContractInfoForm>
        </div>
      </Modal>
    </>
  );
};

export default HrEmployeeDetailCardEmploymentHistory;
