import HrAddEmployeeForm from "../../../components/HRUsersComp/hrEmployeeManagementComp/hrEmployeeforms/HrEmployee-Add-Employee-form";
import "../userPage.css";

export const HREmployeeManagmentAddPage = () => {
  return (
    <>
      <div className="w3-container">
        <HrAddEmployeeForm></HrAddEmployeeForm>
      </div>
    </>
  );
};

export default HREmployeeManagmentAddPage;
