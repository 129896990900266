import Tabs, { TabsProps } from "antd/es/tabs";
import "./HrTimeAndAttedance.css";
import HrAttendanceEmployeeListWrapper from "./hrTimeList/HrAttendanceEmployeeListWrapper";
import HrTimeSheetListWrapper from "./hrTimeList/HrTimeSheetListWrapper";
import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";

export const HrTimeAndAttedanceWrapper = () => {
  const onChange = (key: string) => {
    console.log(key);
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Time Sheet",
      children: (
        <>
          <HrTimeSheetListWrapper></HrTimeSheetListWrapper>
        </>
      ),
    },
    {
      key: "2",
      label: "Attendance",
      children: (
        <>
          <HrAttendanceEmployeeListWrapper></HrAttendanceEmployeeListWrapper>
        </>
      ),
    },
  ];
  return (
    <>
      <div className="w3-col" style={{ minHeight: "400px" }}>
        <div className="w3-col" style={{ marginTop: "20px" }}>
          <div className="w3-container">
            <div className="w3-content">
              <div className="w3-col w3-margin-bottom">
                <Breadcrumb
                  items={[
                    {
                      title: (
                        <span className="siteBorderPrimaryColor w3-large">
                          <Link to={"/hr"}>HRCLOUD</Link>
                        </span>
                      ),
                    },
                    {
                      title: (
                        <span className="siteBorderPrimaryColor w3-large">
                          Time & Attandance
                        </span>
                      ),
                    },
                  ]}
                />
                <br />
                <p>
                  Borem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                  vulputate libero et velit interdum, ac aliquet odio mattis.
                  <br />
                  Class aptent taciti sociosqu ad litora torquent per conubia
                  nostra, per inceptos himenaeos.
                </p>
              </div>

              <div className="w3-col  w3-margin-bottom">
                <Tabs
                  tabBarStyle={{
                    borderBottom: "1px solid #0D333E",
                    fontSize: "16px",
                  }}
                  defaultActiveKey="1"
                  items={items}
                  onChange={onChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HrTimeAndAttedanceWrapper;
