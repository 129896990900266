import instance from "../utils/axios.wrapper";
import { convertObjToQueryParams } from "../utils/basic.utils";
import { IAuthType } from "./authService.type";

export const sampleApiCall = async (body?: any, id?: any) => {
  const axios = await instance("", "https://dummyjson.com/", true);
  const { data } = await axios.get("/todos");
  const result: IAuthType = await data;
  return result;
};

export const authRefreshSession = async () => {
  const axios = await instance(null, null, true);
  const { data } = await axios.get("api/core/v1/auth/sessions-login");
  const result = await data;
  return result;
};

export const authRefreshSessionToken = async (body: any) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.post("api/core/v1/auth/refresh-token", body);
  const result: IAuthType = await data;
  return result;
};

// Departments APIS
export const hrAddDepartment = async (body: any) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.post("api/hrm/v1/departments", body);
  const result: IAuthType = await data;
  return result;
};

export const hrGetDepartments = async (body?: any) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.get(
    `api/hrm/v1/departments${convertObjToQueryParams(body)}`
  );
  const result = await data;
  return result;
};

export const hrGetDepartment = async (id: number | string) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.get(`api/hrm/v1/departments/${id}`);
  const result = await data;
  return result;
};

export const hrUpdateDepartment = async (body: any) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.patch("api/hrm/v1/departments", body);
  const result = await data;
  return result;
};

export const hrDeleteDepartment = async (id: number | string) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.delete(`api/hrm/v1/departments/${id}`);
  const result = await data;
  return result;
};

// Positions APIS
export const hrAddPosition = async (body: any) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.post("api/hrm/v1/positions", body);
  const result = await data;
  return result;
};

export const hrGetPositions = async (body?: any) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.get(
    `api/hrm/v1/positions${convertObjToQueryParams(body)}`
  );
  const result = await data;
  return result;
};

export const hrGetPosition = async (id: number | string) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.get(`api/hrm/v1/positions/${id}`);
  const result = await data;
  return result;
};

export const hrUpdatePosition = async (body: any) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.patch("api/hrm/v1/positions", body);
  const result = await data;
  return result;
};

export const hrDeletePosition = async (id: number | string) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.delete(`api/hrm/v1/positions/${id}`);
  const result = await data;
  return result;
};

// Employee Types Apis

export const hrAddEmploymentType = async (body: any) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.post("api/hrm/v1/employment-types", body);
  const result: IAuthType = await data;
  return result;
};

export const hrGetEmploymentTypes = async (body?: any) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.get(
    `api/hrm/v1/employment-types${convertObjToQueryParams(body)}`
  );
  const result = await data;
  return result;
};

export const hrGetEmploymentType = async (id: number | string) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.get(`api/hrm/v1/employment-types/${id}`);
  const result = await data;
  return result;
};

export const hrUpdateEmploymentType = async (body: any) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.patch("api/hrm/v1/employment-types", body);
  const result = await data;
  return result;
};

export const hrDeleteEmploymentType = async (id: number | string) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.delete(`api/hrm/v1/employment-types/${id}`);
  const result = await data;
  return result;
};

// Locations Types Apis

export const hrAddLocationType = async (body: any) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.post("api/hrm/v1/location-types", body);
  const result: IAuthType = await data;
  return result;
};

export const hrGetLocationTypes = async (body?: any) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.get(
    `api/hrm/v1/location-types${convertObjToQueryParams(body)}`
  );
  const result = await data;
  return result;
};

export const hrGetLocationType = async (id: number | string) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.get(`api/hrm/v1/location-types/${id}`);
  const result = await data;
  return result;
};

export const hrUpdateLocationType = async (body: any) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.patch("api/hrm/v1/location-types", body);
  const result = await data;
  return result;
};

export const hrDeleteLocationType = async (id: number | string) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.delete(`api/hrm/v1/location-types/${id}`);
  const result = await data;
  return result;
};

// Holidays Api

export const hrAddHolidays = async (body: any) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.post("api/hrm/v1/holidays", body);
  const result: IAuthType = await data;
  return result;
};

export const hrGetHolidayss = async (body?: any) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.get(
    `api/hrm/v1/holidays${convertObjToQueryParams(body)}`
  );
  const result = await data;
  return result;
};

export const hrGetHolidays = async (id: number | string) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.get(`api/hrm/v1/holidays/${id}`);
  const result = await data;
  return result;
};

export const hrUpdateHolidays = async (body: any) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.patch("api/hrm/v1/holidays", body);
  const result = await data;
  return result;
};

export const hrDeleteHolidays = async (id: number | string) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.delete(`api/hrm/v1/holidays/${id}`);
  const result = await data;
  return result;
};

// Team Api

export const hrAddTeam = async (body: any) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.post("api/hrm/v1/teams", body);
  const result: IAuthType = await data;
  return result;
};

export const hrGetTeams = async (body?: any) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.get(
    `api/hrm/v1/teams${convertObjToQueryParams(body)}`
  );
  const result = await data;
  return result;
};

export const hrGetTeam = async (id: number | string) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.get(`api/hrm/v1/teams/${id}`);
  const result = await data;
  return result;
};

export const hrUpdateTeam = async (body: any) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.patch("api/hrm/v1/teams", body);
  const result = await data;
  return result;
};

export const hrDeleteTeam = async (id: number | string) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.delete(`api/hrm/v1/teams/${id}`);
  const result = await data;
  return result;
};

// Membership
export const authGetMemberships = async (body?: any) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.get(
    `api/core/v1/memberships${convertObjToQueryParams(body)}`
  );
  const result = await data;
  return result;
};

export const authGetMembership = async (id: number | string) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.get(`api/core/v1/memberships/${id}`);
  const result = await data;
  return result;
};

export const authGetDefaultMembership = async (body?: any) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.post("api/core/v1/memberships/default", body);
  const result = await data;
  return result;
};
