import { UserOutlined } from "@ant-design/icons";
import "./hrEmployeeManagementComp.css";

type IHrEmployeeDetailCard = {
  employeeManagementData?: any | null;
  refreshPage?: () => void;
};

export const HrEmployeeDetailCard: React.FC<IHrEmployeeDetailCard> = ({
  employeeManagementData,
  refreshPage,
}) => {
  return (
    <>
      <div className="w3-col" style={{ minHeight: "100px" }}>
        {/* Users Management Details Card */}
        <div
          className="w3-col"
          style={{ marginTop: "10px", paddingBottom: "50px" }}
        >
          <div className="w3-container">
            <div className="w3-content">
              <div style={{ padding: "10px" }}>
                <h3> Employee </h3>
              </div>
              <div>
                <div className="w3-col l3 s12 m12 w3-margin-top w3-margin-bottom">
                  <div className="w3-center" style={{ marginTop: "25px" }}>
                    <img
                      style={{ width: "150px", height: "150px" }}
                      className="w3-circle"
                      src={`${
                        process.env.PUBLIC_URL +
                        "/images/auth/profileSample.svg"
                      }`}
                      alt=""
                    />
                  </div>
                </div>

                <div className="w3-col l9 s12 m12 w3-margin-top  w3-margin-bottom">
                  {/* First Level Details */}
                  <div
                    className="w3-col w3-margin-Bottom"
                    style={{ marginTop: "10px" }}
                  >
                    {/* FullName */}
                    <div className="w3-col" style={{ padding: "2px" }}>
                      <span className="userProfileLabel">
                        <UserOutlined /> FullName
                      </span>
                      <br />
                      <span className="userProfileLabelValue">
                        <b>James John </b>
                      </span>
                      <br />
                      <br />
                    </div>

                    {/* UserID */}
                    <div className="w3-col" style={{ padding: "2px" }}>
                      <span className="userProfileLabel">
                        <UserOutlined /> Department
                      </span>
                      <br />
                      <span className="userProfileLabelValue">
                        <b> Staff </b>
                      </span>
                      <br />
                      <br />
                    </div>

                    {/* Email Address */}
                    <div className="w3-col" style={{ padding: "2px" }}>
                      <span className="userProfileLabel">
                        <UserOutlined /> Email
                      </span>
                      <br />
                      <span className="userProfileLabelValue">
                        <b> email@gmail.com</b>
                      </span>
                      <br />
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HrEmployeeDetailCard;
