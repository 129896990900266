import { Button, notification, Pagination, Result, Tag } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { IHrEmployeeTypeData } from "../../../../apiservice/employmentService.type";
import { hrGetEmployees } from "../../../../apiservice/employmentServices";
import useFormatApiRequest from "../../../../hooks/formatApiRequest";
import { convertToShortDate } from "../../../../utils/date.utils";
import { ILoadState } from "../../../../utils/loading.utils.";
import "../hrEmployeeManagementComp.css";
type NotificationType = "success" | "info" | "warning" | "error";

type IHrEmployeeDatabaseList = {
  externalFilter?: any;
  initialDefaultFilter?: any;
  hidePagination?: boolean;
};

export const HrEmployeeDatabaseList: React.FC<IHrEmployeeDatabaseList> = ({
  externalFilter,
  initialDefaultFilter,
  hidePagination = false,
}) => {
  const [usersLoadState, setUsersLoadState] = useState<ILoadState>("loading");
  const [loadUserssData, setLoadUserssData] = useState(true);
  const [usersDefaultFilter, setUsersDefaultFilter] = useState(
    initialDefaultFilter || { page: 1, perPage: 10 }
  );
  const [tableData, setTableData] = useState<IHrEmployeeTypeData[]>([]);
  const [api, contextHolder] = notification.useNotification();

  // Pagination Constant/Variables
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const perPage = 10;

  // Use Effect to reload API when External Filter has changed
  useEffect(() => {
    if (externalFilter) {
      setCurrentPage(1);
      setUsersDefaultFilter({
        ...usersDefaultFilter,
        ...externalFilter,
        page: 1,
      });
      setLoadUserssData(true);
      setUsersLoadState("loading");
    }
  }, [externalFilter]);

  // A custom hook to Load All Userss
  const UsersDataResult = useFormatApiRequest(
    () => hrGetEmployees(usersDefaultFilter),
    loadUserssData,
    () => {
      setLoadUserssData(false);
    },
    () => {
      processUserssResult();
    }
  );

  // Process The Current Users Data Result
  const processUserssResult = async () => {
    if (UsersDataResult.httpState === "SUCCESS") {
      setTableData(UsersDataResult.data?.data || []);
      setTotalItems(UsersDataResult.data?.meta?.total || 1);
      setUsersLoadState("completed");
    } else if (UsersDataResult.httpState === "ERROR") {
      setUsersLoadState("error");
    } else if (UsersDataResult.httpState === "LOADING") {
      setUsersLoadState("loading");
    }
  };

  // Show Notification
  const openNotificationWithIcon = (
    type: NotificationType,
    message: string,
    description: string,
    background?: string
  ) => {
    api[type]({
      message,
      description,
      placement: "bottomRight",
      style: { background },
    });
  };

  // Table Configuration
  const columns: ColumnsType<IHrEmployeeTypeData> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 200,
      render: (text, records, index) => (
        <span>
          <img
            className="w3-circle"
            style={{ width: "30px" }}
            src={`${process.env.PUBLIC_URL + "/images/auth/profileSample.svg"}`}
            alt=""
          />
          <span>
            <b className="w3-small">
              {records?.firstName + " " + records?.lastName}
            </b>
          </span>
        </span>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, records, index) => (
        <Tag className="tagText" color={"green"}>
          {"Active"}
        </Tag>
      ),
    },
    {
      title: "Email Address",
      dataIndex: "email",
      key: "email",
      width: 200,
      render: (text, records, index) => <span>{records?.email}</span>,
    },
    {
      title: "Job Title",
      dataIndex: "jobTitle",
      key: "jobTitle",
      render: (text, records, index) => (
        <span>{records?.currentEmploymentInfo?.profession}</span>
      ),
    },
    {
      title: "Department",
      dataIndex: "department",
      key: "department",
      render: (text, records, index) => (
        <Tag className="tagText" color={"green"}>
          {records?.currentEmploymentInfo?.department?.title}
        </Tag>
      ),
    },
    {
      title: "Details",
      dataIndex: "detail",
      key: "details",
      render: (text, records, index) => (
        <Link
          to={`/hr/employee-management/${records?.id}/${records?.firstName}`}
          className="sitePrimaryColor"
        >
          <u>Details</u>
        </Link>
      ),
    },
    {
      title: "Date",
      dataIndex: "dateModified",
      key: "dateModified",
      render: (text) => <span>{convertToShortDate(text)}</span>,
    },
    // {
    //   title: "Action",
    //   dataIndex: "id",
    //   key: "select",
    //   fixed: "right",
    //   width: 120,
    //   render: (text, records, index) => (
    //     <div className="w3-center">
    //       <span style={{ zoom: 1.2 }}>
    //         <EditOutlined />
    //       </span>
    //       &nbsp; &nbsp;
    //       <span style={{ zoom: 1.2 }}>
    //         <DeleteOutlined />
    //       </span>
    //     </div>
    //   ),
    // },
  ];

  // Use to Control Pagination On Change Event
  const onPageChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setUsersDefaultFilter({
      ...usersDefaultFilter,
      page: page,
      perPage: pageSize,
    });
    setLoadUserssData(true);
  };

  return (
    <>
      {/* " The context is use to hold the notification from ant design" */}
      {contextHolder}

      {/* " Show Loading Error" */}
      {usersLoadState === "error" && (
        <div className="w3-col w3-padding-bottom">
          <Result
            status="500"
            title="Error"
            subTitle="Sorry, something went wrong, it could be a network Related error"
            extra={
              <Button onClick={() => setLoadUserssData(true)} type="primary">
                Reload
              </Button>
            }
          />
        </div>
      )}

      {/* " Show Loading Table" */}
      {usersLoadState !== "error" && (
        <>
          <div className="w3-col w3-margin-top">
            <Table
              loading={usersLoadState === "loading"}
              rowKey="id"
              size="small"
              columns={columns}
              scroll={{ x: 950 }}
              dataSource={tableData}
              pagination={{ hideOnSinglePage: true }}
            />
          </div>

          {!hidePagination && (
            <div className="w3-col w3-margin-top">
              <Pagination
                current={currentPage || 1}
                onChange={onPageChange}
                pageSize={perPage}
                total={totalItems}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default HrEmployeeDatabaseList;
