import { LoadingOutlined } from "@ant-design/icons";
import { Select, Input } from "antd";
import { useState, useEffect } from "react";
import {
  authGetMemberships,
  hrGetDepartments,
  hrGetEmploymentTypes,
  hrGetLocationTypes,
  hrGetPositions,
} from "../../../../apiservice/authService";
import {
  IAuthMembershipTypeData,
  IHrDepartmentTypeData,
  IHrEmploymentTypeData,
  IHrLocationTypeData,
  IHrRanksTypeData,
} from "../../../../apiservice/authService.type";
import { hrAddEmployee } from "../../../../apiservice/employmentServices";
import { places } from "../../../../apiservice/places";
import { IPlace } from "../../../../apiservice/places.type";
import useFormatApiRequest from "../../../../hooks/formatApiRequest";
import "../hrEmployeeManagementComp.css";
const { Option } = Select;

type IHrAddEmployeeForm = {
  onFormSuccess?: () => void;
  onFormFailure?: (err?: string) => void;
};

export const HrAddEmployeeForm: React.FC<IHrAddEmployeeForm> = ({
  onFormFailure,
  onFormSuccess,
}) => {
  const [loadApi, setLoadApi] = useState(false);
  const [payLoad, setpayLoad] = useState<any>(null);
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [loadAuth, setLoadAuth] = useState(false);
  const [country, setCountry] = useState<any[]>([]);
  const [city, setCity] = useState<any[]>([]);
  const [countryCode, setCountryCode] = useState<any[]>([]);
  const allPlace: IPlace[] = places as IPlace[];

  const [loadContractType, setLoadService] = useState(true);
  const [contractTypeListData, setContractTypeListData] = useState<
    IHrEmploymentTypeData[]
  >([]);

  const [loadLocationType, setLoadLocationType] = useState(true);
  const [locationTypeListData, setLocationTypeListData] = useState<
    IHrLocationTypeData[]
  >([]);

  const [loadDepartment, setLoadDepartment] = useState(true);
  const [departmentListData, setDepartmentListData] = useState<
    IHrDepartmentTypeData[]
  >([]);

  const [loadJobRank, setLoadJobRank] = useState(true);
  const [jobRankListData, setJobRankListData] = useState<IHrRanksTypeData[]>(
    []
  );

  const [loadUserMembership, setLoadUserMembership] = useState(true);
  const [userMembershipListData, setUserMembershipListData] = useState<
    IAuthMembershipTypeData[]
  >([]);

  // Use to collect Input change Change
  const handleInputChange = (event) => {
    const name = event.target.name || event.target.id;
    const value = event.target.value;
    setpayLoad((values) => ({ ...values, [name]: value }));
  };

  const handleSelectChange = (value, name) => {
    setpayLoad((values) => ({ ...values, [name]: value }));
    if (name === "country") {
      updateState(value);
    }
  };

  const updateCountry = () => {
    const countries = allPlace.map((place) => {
      return { name: place.emoji + " " + place.name, value: place.name };
    });

    const countriesCode = allPlace.map((place) => {
      return {
        name: place.emoji + " " + place.phone_code,
        value: place.phone_code,
      };
    });

    setCountry(countries);
    setCountryCode(countriesCode);
  };

  const updateState = (selectedCountry) => {
    let states;
    if (getCountryIndex(selectedCountry) === -1) {
      states = [];
    } else {
      states = allPlace[getCountryIndex(selectedCountry)].states.map(
        (state) => state.name
      );
    }

    setCity(states);
  };

  const getCountryIndex = (selectedCountry: string) => {
    console.log("Selected Country", selectedCountry);
    return country.findIndex((country) => selectedCountry === country?.value);
  };

  useEffect(() => {
    updateCountry();
  }, []);

  //#region  For Loading a seleect List

  // Custom hook to load all contractType
  const contractTypeDataResult = useFormatApiRequest(
    () => hrGetEmploymentTypes(),
    loadContractType,
    () => {
      setLoadService(false);
    },
    () => {
      processGetContractTypeResult();
    }
  );

  // Process the contract Type data result
  const processGetContractTypeResult = async () => {
    if (contractTypeDataResult.httpState === "SUCCESS") {
      const resultData: any[] = contractTypeDataResult.data?.data;
      setContractTypeListData(resultData);
    } else if (contractTypeDataResult.httpState === "ERROR") {
      console.log("Error Loading Contract Type");
    } else if (contractTypeDataResult.httpState === "LOADING") {
      console.log("Loading Contract Type...");
    }
  };

  // Custom hook to load all location types
  const locationTypeDataResult = useFormatApiRequest(
    () => hrGetLocationTypes(),
    loadLocationType,
    () => {
      setLoadLocationType(false);
    },
    () => {
      processGetLocationTypeResult();
    }
  );

  // Process the location type data result
  const processGetLocationTypeResult = async () => {
    if (locationTypeDataResult.httpState === "SUCCESS") {
      const resultData: any[] = locationTypeDataResult.data?.data;
      setLocationTypeListData(resultData);
    } else if (locationTypeDataResult.httpState === "ERROR") {
      console.log("Error Loading Location Type");
    } else if (locationTypeDataResult.httpState === "LOADING") {
      console.log("Loading Location Type...");
    }
  };

  // Custom hook to load all departments
  const departmentDataResult = useFormatApiRequest(
    () => hrGetDepartments(),
    loadDepartment,
    () => {
      setLoadDepartment(false);
    },
    () => {
      processGetDepartmentResult();
    }
  );

  // Process the department data result
  const processGetDepartmentResult = async () => {
    if (departmentDataResult.httpState === "SUCCESS") {
      const resultData: any[] = departmentDataResult.data?.data;
      setDepartmentListData(resultData);
    } else if (departmentDataResult.httpState === "ERROR") {
      console.log("Error Loading Department");
    } else if (departmentDataResult.httpState === "LOADING") {
      console.log("Loading Department...");
    }
  };

  // Custom hook to load all job ranks
  const jobRankDataResult = useFormatApiRequest(
    () => hrGetPositions(),
    loadJobRank,
    () => {
      setLoadJobRank(false);
    },
    () => {
      processGetJobRankResult();
    }
  );

  // Process the job rank data result
  const processGetJobRankResult = async () => {
    if (jobRankDataResult.httpState === "SUCCESS") {
      const resultData: any[] = jobRankDataResult.data?.data;
      setJobRankListData(resultData);
    } else if (jobRankDataResult.httpState === "ERROR") {
      console.log("Error Loading Job Rank");
    } else if (jobRankDataResult.httpState === "LOADING") {
      console.log("Loading Job Rank...");
    }
  };

  // Custom hook to load all user memberships
  const userMembershipDataResult = useFormatApiRequest(
    () => authGetMemberships(),
    loadUserMembership,
    () => {
      setLoadUserMembership(false);
    },
    () => {
      processGetUserMembershipResult();
    }
  );

  // Process the user membership data result
  const processGetUserMembershipResult = async () => {
    if (userMembershipDataResult.httpState === "SUCCESS") {
      const resultData: any[] = userMembershipDataResult.data?.data;
      setUserMembershipListData(resultData);
    } else if (userMembershipDataResult.httpState === "ERROR") {
      console.log("Error Loading User Membership");
    } else if (userMembershipDataResult.httpState === "LOADING") {
      console.log("Loading User Membership...");
    }
  };

  //#region  For Loading a seleect List --- End

  // Use to Submit Form
  const handleSubmit = (event: any) => {
    event.preventDefault();
    console.log(getFormattedData());
    setLoadApi(true);
    setFormLoading(true);
  };

  // A custom hook to Process Form
  const result = useFormatApiRequest(
    () => hrAddEmployee(getFormattedData()),
    loadApi,
    () => {
      setLoadApi(false);
    },
    () => {
      processFormApi();
    }
  );

  // Process Api
  const processFormApi = async () => {
    if (result.httpState === "SUCCESS") {
      setFormLoading(false);
      setLoadAuth(true);
      if (onFormSuccess) {
        onFormSuccess();
      }
      alert("Employee Has been added.");
      // Handle Success Here
    } else if (result.httpState === "ERROR") {
      setFormLoading(false);
      if (onFormFailure) {
        onFormFailure(
          result.data?.response?.data?.errors?.[0].message ||
            result.data?.response?.data?.message ||
            result.errorMsg
        );
      }
      alert(
        result.data?.response?.data?.errors?.[0].message ||
          result.data?.response?.data?.message ||
          result.errorMsg
      );
      //Handle Error Here
    }
  };

  // Formata Data for Processing
  const getFormattedData = () => {
    const data = {
      membershipId: null,
      firstName: payLoad?.firstName,
      lastName: payLoad?.lastName,
      otherNames: payLoad?.otherNames,
      email: payLoad?.email,
      phoneNumber: payLoad?.phoneNumber,
      country: payLoad?.country,
      city: payLoad?.city,
      state: payLoad?.address,
      maritalStatus: payLoad?.maritalStatus,
      birthDate: payLoad?.birthDate,
      employmentInfo: {
        profession: payLoad?.jobTitle,
        departmentId: payLoad?.jobDepartment,
        employmentTypeId: payLoad?.contractType,
        locationTypeId: payLoad?.locationType,
        startDate: payLoad?.startDate,
        endDate: null,
        location: "",
      },
      emergencyContact: {
        name: payLoad?.emergencyFullname,
        email: payLoad?.emergencyEmail,
        phoneNumber: payLoad?.emergencyPhoneNumber,
        relationship: payLoad?.emergencyRelationship,
        location: payLoad?.emergencyAddress,
      },
      nextOfKin: {
        name: payLoad?.nextOfKinFullname,
        email: payLoad?.nextOfKinEmail,
        phoneNumber: payLoad?.nextOfKinPhoneNumber,
        relationship: payLoad?.nextOfKinRelationship,
        location: payLoad?.nextOfKinAddress,
      },
      bankingDetail: {
        accountName: payLoad?.accountName,
        accountNumber: payLoad?.accountNumber,
        bankName: payLoad?.bankName,
        paymentType: 1,
      },
    };
    return data;
  };

  return (
    <div>
      <>
        <form onSubmit={handleSubmit}>
          {/* General Information */}
          <div className="grid mb-4 pb-2 pt-2 mt-4 border-t border-b">
            <h3 className="font-semibold"> General Information </h3>

            {/* User */}
            <div className="w3-col">
              <div className="w3-col w3-padding">
                <div>
                  <span>
                    Select User <span className="w3-text-red">*</span>
                  </span>
                </div>
                <Select
                  id="user"
                  value={payLoad?.user || ""}
                  onChange={(val) => {
                    handleSelectChange(val, "user");
                  }}
                  showSearch
                  className="w3-col w3-round-xlarge"
                  style={{ width: "100%", height: "50px" }}
                >
                  <Option value="">Select User</Option>
                  {userMembershipListData.map((item, index: number) => (
                    <Option key={index} value={item?.id}>
                      {item?.user?.firstName + " " + item?.user?.lastName}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>

            {/* First Name */}
            <div className="w3-col">
              <div className="w3-col w3-margin-bottom w3-padding">
                <div>
                  <span>
                    First Name <span className="w3-text-red">*</span>
                  </span>
                </div>
                <Input
                  required
                  name="firstName"
                  value={payLoad?.firstName}
                  onChange={handleInputChange}
                  type="text"
                  className="w3-col inputField"
                  placeholder="First Name"
                  style={{ width: "100%", height: "50px" }}
                />
              </div>
            </div>

            {/* Last Name */}
            <div className="w3-col">
              <div className="w3-col w3-margin-bottom w3-padding">
                <div>
                  <span>
                    Last Name <span className="w3-text-red">*</span>
                  </span>
                </div>
                <Input
                  required
                  name="lastName"
                  value={payLoad?.lastName}
                  onChange={handleInputChange}
                  type="text"
                  className="w3-col inputField"
                  placeholder="Last Name"
                  style={{ width: "100%", height: "50px" }}
                />
              </div>
            </div>

            {/* Other Names */}
            <div className="w3-col">
              <div className="w3-col w3-margin-bottom w3-padding">
                <div>
                  <span>Other Names</span>
                </div>
                <Input
                  name="otherNames"
                  value={payLoad?.otherNames}
                  onChange={handleInputChange}
                  type="text"
                  className="w3-col inputField"
                  placeholder="Other Names"
                  style={{ width: "100%", height: "50px" }}
                />
              </div>
            </div>

            {/* Email */}
            <div className="w3-col">
              <div className="w3-col w3-margin-bottom w3-padding">
                <div>
                  <span>
                    Email <span className="w3-text-red">*</span>
                  </span>
                </div>
                <Input
                  required
                  name="email"
                  value={payLoad?.email}
                  onChange={handleInputChange}
                  type="email"
                  className="w3-col inputField"
                  placeholder="Email"
                  style={{ width: "100%", height: "50px" }}
                />
              </div>
            </div>

            {/* Select Phone */}
            <div className="w3-col">
              <div className="w3-col w3-margin-bottom w3-padding">
                <span className="inputTopName">
                  Phone Number <span className="w3-text-red"> * </span>
                </span>
                <div className="w3-col">
                  <div className="w3-col" style={{ width: "120px" }}>
                    <Select
                      id="countryCode"
                      showSearch
                      value={payLoad?.countryCode || "+234"}
                      onChange={(val) => {
                        handleSelectChange(val, "countryCode");
                      }}
                      className="w3-col"
                      placeholder="Country Code"
                      style={{ width: "100%", height: "50px" }}
                    >
                      <Option value="+234">+234</Option>
                      {countryCode.map((item: any, index: number) => (
                        <Option key={index} value={item?.value}>
                          {item?.name}
                        </Option>
                      ))}
                    </Select>
                  </div>
                  <div className="w3-rest">
                    <Input
                      required
                      name="phoneNumber"
                      value={payLoad?.phoneNumber || ""}
                      onChange={handleInputChange}
                      style={{ height: "50px" }}
                      className="w3-col inputField"
                      placeholder="Phone Number"
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* Select Country */}
            <div className="w3-col">
              <div className="w3-col w3-margin-bottom w3-padding">
                <span className="inputTopName"> Country (Optional)</span>
                <br />
                <Select
                  id="country"
                  value={payLoad?.country || ""}
                  onChange={(val) => {
                    handleSelectChange(val, "country");
                  }}
                  showSearch
                  className="w3-col"
                  style={{ width: "100%", height: "50px" }}
                >
                  <Option value="">Select Country</Option>
                  {country.map((item: any, index: number) => (
                    <Option key={index} value={item?.value}>
                      {item?.name}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>

            {/* Select City */}
            <div className="w3-col">
              <div className="w3-col w3-margin-bottom w3-padding">
                <span className="inputTopName"> City (Optional)</span>
                <Select
                  id="city"
                  value={payLoad?.city || ""}
                  onChange={(val) => {
                    handleSelectChange(val, "city");
                  }}
                  showSearch
                  className="w3-col"
                  style={{ width: "100%", height: "50px" }}
                >
                  <Option value="">Select City</Option>
                  {city.map((item: string, index: number) => (
                    <Option key={index} value={item}>
                      {item}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>

            {/* Address */}
            <div className="w3-col">
              <div className="w3-col w3-margin-bottom w3-padding">
                <div>
                  <span>
                    Address <span className="w3-text-red">*</span>
                  </span>
                </div>
                <Input
                  required
                  name="address"
                  value={payLoad?.address}
                  onChange={handleInputChange}
                  type="text"
                  className="w3-col inputField"
                  placeholder="Address"
                  style={{ width: "100%", height: "50px" }}
                />
              </div>
            </div>

            {/* Marital Status */}
            <div className="w3-col">
              <div className="w3-col w3-padding">
                <div>
                  <span>
                    Marital Status <span className="w3-text-red">*</span>
                  </span>
                </div>
                <select
                  id="maritalStatus"
                  value={payLoad?.maritalStatus || ""}
                  onChange={handleInputChange}
                  className="w3-col w3-round w3-white w3-border w3-padding"
                  style={{ width: "100%", height: "50px" }}
                >
                  <option value="">Select Marital Status</option>
                  <option value="single">Single</option>
                  <option value="married">Married</option>
                  <option value="divorced">Divorced</option>
                  <option value="widowed">Widowed</option>
                </select>
              </div>
            </div>

            {/* Birth Date */}
            <div className="w3-col">
              <div className="w3-col w3-padding">
                <div>
                  <span>
                    Birth Date <span className="w3-text-red">*</span>
                  </span>
                </div>
                <Input
                  required
                  type="date"
                  name="birthDate"
                  value={payLoad?.birthDate || ""}
                  onChange={handleInputChange}
                  className="w3-col inputField"
                  style={{ width: "100%", height: "50px" }}
                />
              </div>
            </div>

            {/* User Image */}
            {/* <div className="w3-col">
              <div className="w3-col w3-margin-bottom w3-padding">
                <div>
                  <span>
                    User Image <span className="w3-text-red">*</span>
                  </span>
                </div>
                <input
                  required
                  type="file"
                  accept="image/*"
                  name="userImage"
                  onChange={handleInputChange}
                  className="w3-col w3-border w3-round-large w3-padding"
                  style={{ width: "100%", height: "50px" }}
                />
              </div>
            </div> */}
          </div>

          {/* Emergency Contact */}
          <div className="grid mb-4 pb-2 pt-2 mt-4  border-b">
            <h3 className="font-semibold"> Emergency Contact</h3>

            {/* Emergency Full Name */}
            <div className="w3-col">
              <div className="w3-col w3-margin-bottom w3-padding">
                <div>
                  <span>
                    Emergency Full Name <span className="w3-text-red">*</span>
                  </span>
                </div>
                <Input
                  required
                  name="emergencyFullname"
                  value={payLoad?.emergencyFullname}
                  onChange={handleInputChange}
                  type="text"
                  className="w3-col inputField"
                  placeholder="Emergency Full Name"
                  style={{ width: "100%", height: "50px" }}
                />
              </div>
            </div>

            {/* Emergency Relationship */}
            <div className="w3-col">
              <div className="w3-col w3-margin-bottom w3-padding">
                <div>
                  <span>
                    Emergency Relationship{" "}
                    <span className="w3-text-red">*</span>
                  </span>
                </div>
                <Input
                  required
                  name="emergencyRelationship"
                  value={payLoad?.emergencyRelationship}
                  onChange={handleInputChange}
                  type="text"
                  className="w3-col inputField"
                  placeholder="Emergency Relationship"
                  style={{ width: "100%", height: "50px" }}
                />
              </div>
            </div>

            {/* Emergency Email */}
            <div className="w3-col">
              <div className="w3-col w3-margin-bottom w3-padding">
                <div>
                  <span>
                    Emergency Email <span className="w3-text-red">*</span>
                  </span>
                </div>
                <Input
                  required
                  name="emergencyEmail"
                  value={payLoad?.emergencyEmail}
                  onChange={handleInputChange}
                  type="email"
                  className="w3-col inputField"
                  placeholder="Emergency Email"
                  style={{ width: "100%", height: "50px" }}
                />
              </div>
            </div>

            {/* Select Emergency Phone */}
            <div className="w3-col">
              <div className="w3-col w3-margin-bottom w3-padding">
                <span className="inputTopName">
                  Emergency Phone Number{" "}
                  <span className="w3-text-red"> * </span>
                </span>
                <div className="w3-col">
                  <div className="w3-col" style={{ width: "120px" }}>
                    <Select
                      id="emergencyCountryCode"
                      showSearch
                      value={payLoad?.emergencyCountryCode || "+234"}
                      onChange={(val) => {
                        handleSelectChange(val, "emergencyCountryCode");
                      }}
                      className="w3-col"
                      placeholder="Country Code"
                      style={{ width: "100%", height: "50px" }}
                    >
                      <Option value="+234">+234</Option>
                      {countryCode.map((item: any, index: number) => (
                        <Option key={index} value={item?.value}>
                          {item?.name}
                        </Option>
                      ))}
                    </Select>
                  </div>
                  <div className="w3-rest">
                    <Input
                      required
                      name="emergencyPhoneNumber"
                      value={payLoad?.emergencyPhoneNumber || ""}
                      onChange={handleInputChange}
                      style={{ height: "50px" }}
                      className="w3-col inputField"
                      placeholder="Emergency Phone Number"
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* Select Emergency Country */}
            {/* <div className="w3-col">
              <div className="w3-col w3-margin-bottom w3-padding">
                <span className="inputTopName">
                  {" "}
                  Emergency Country (Optional)
                </span>
                <br />
                <Select
                  id="emergencyCountry"
                  value={payLoad?.emergencyCountry || ""}
                  onChange={(val) => {
                    handleSelectChange(val, "emergencyCountry");
                  }}
                  showSearch
                  className="w3-col"
                  style={{ width: "100%", height: "50px" }}
                >
                  <Option value="">Select Emergency Country</Option>
                  {country.map((item: any, index: number) => (
                    <Option key={index} value={item?.value}>
                      {item?.name}
                    </Option>
                  ))}
                </Select>
              </div>
            </div> */}

            {/* Select Emergency City */}
            {/* <div className="w3-col">
              <div className="w3-col w3-margin-bottom w3-padding">
                <span className="inputTopName"> Emergency City (Optional)</span>
                <Select
                  id="emergencyCity"
                  value={payLoad?.emergencyCity || ""}
                  onChange={(val) => {
                    handleSelectChange(val, "emergencyCity");
                  }}
                  showSearch
                  className="w3-col"
                  style={{ width: "100%", height: "50px" }}
                >
                  <Option value="">Select Emergency City</Option>
                  {city.map((item: string, index: number) => (
                    <Option key={index} value={item}>
                      {item}
                    </Option>
                  ))}
                </Select>
              </div>
            </div> */}

            {/* Select Emergency Address */}
            <div className="w3-col">
              <div className="w3-col w3-margin-bottom w3-padding">
                <span className="inputTopName">
                  Emergency Address (Optional)
                </span>
                <Input
                  id="emergencyAddress"
                  name="emergencyAddress"
                  value={payLoad?.emergencyAddress || ""}
                  onChange={handleInputChange}
                  className="w3-col inputField"
                  style={{ width: "100%", height: "50px" }}
                  placeholder="Emergency Address"
                />
              </div>
            </div>
          </div>

          {/* Next Of Kin  */}
          <div className="grid mb-4 pb-2 pt-2 mt-4  border-b">
            <h3 className="font-semibold"> Next Of Kin </h3>

            {/* Next of Kin Full Name */}
            <div className="w3-col">
              <div className="w3-col w3-margin-bottom w3-padding">
                <div>
                  <span>
                    Next of Kin Full Name <span className="w3-text-red">*</span>
                  </span>
                </div>
                <Input
                  required
                  name="nextOfKinFullname"
                  value={payLoad?.nextOfKinFullname}
                  onChange={handleInputChange}
                  type="text"
                  className="w3-col inputField"
                  placeholder="Next of Kin Full Name"
                  style={{ width: "100%", height: "50px" }}
                />
              </div>
            </div>

            {/* Next of Kin Relationship */}
            <div className="w3-col">
              <div className="w3-col w3-margin-bottom w3-padding">
                <div>
                  <span>
                    Next of Kin Relationship{" "}
                    <span className="w3-text-red">*</span>
                  </span>
                </div>
                <Input
                  required
                  name="nextOfKinRelationship"
                  value={payLoad?.nextOfKinRelationship}
                  onChange={handleInputChange}
                  type="text"
                  className="w3-col inputField"
                  placeholder="Next of Kin Relationship"
                  style={{ width: "100%", height: "50px" }}
                />
              </div>
            </div>

            {/* Next of Kin Email */}
            <div className="w3-col">
              <div className="w3-col w3-margin-bottom w3-padding">
                <div>
                  <span>
                    Next of Kin Email <span className="w3-text-red">*</span>
                  </span>
                </div>
                <Input
                  required
                  name="nextOfKinEmail"
                  value={payLoad?.nextOfKinEmail}
                  onChange={handleInputChange}
                  type="email"
                  className="w3-col inputField"
                  placeholder="Next of Kin Email"
                  style={{ width: "100%", height: "50px" }}
                />
              </div>
            </div>

            {/* Select Next of Kin Phone */}
            <div className="w3-col">
              <div className="w3-col w3-margin-bottom w3-padding">
                <span className="inputTopName">
                  Next of Kin Phone Number{" "}
                  <span className="w3-text-red"> * </span>
                </span>
                <div className="w3-col">
                  <div className="w3-col" style={{ width: "120px" }}>
                    <Select
                      id="nextOfKinCountryCode"
                      showSearch
                      value={payLoad?.nextOfKinCountryCode || "+234"}
                      onChange={(val) => {
                        handleSelectChange(val, "nextOfKinCountryCode");
                      }}
                      className="w3-col"
                      placeholder="Country Code"
                      style={{ width: "100%", height: "50px" }}
                    >
                      <Option value="+234">+234</Option>
                      {countryCode.map((item: any, index: number) => (
                        <Option key={index} value={item?.value}>
                          {item?.name}
                        </Option>
                      ))}
                    </Select>
                  </div>
                  <div className="w3-rest">
                    <Input
                      required
                      name="nextOfKinPhoneNumber"
                      value={payLoad?.nextOfKinPhoneNumber || ""}
                      onChange={handleInputChange}
                      style={{ height: "50px" }}
                      className="w3-col inputField"
                      placeholder="Next of Kin Phone Number"
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* Select Next of Kin Country */}
            {/* <div className="w3-col">
              <div className="w3-col w3-margin-bottom w3-padding">
                <span className="inputTopName">
                  {" "}
                  Next of Kin Country (Optional)
                </span>
                <br />
                <Select
                  id="nextOfKinCountry"
                  value={payLoad?.nextOfKinCountry || ""}
                  onChange={(val) => {
                    handleSelectChange(val, "nextOfKinCountry");
                  }}
                  showSearch
                  className="w3-col"
                  style={{ width: "100%", height: "50px" }}
                >
                  <Option value="">Select Next of Kin Country</Option>
                  {country.map((item: any, index: number) => (
                    <Option key={index} value={item?.value}>
                      {item?.name}
                    </Option>
                  ))}
                </Select>
              </div>
            </div> */}

            {/* Select Next of Kin City */}
            {/* <div className="w3-col">
              <div className="w3-col w3-margin-bottom w3-padding">
                <span className="inputTopName">
                  {" "}
                  Next of Kin City (Optional)
                </span>
                <Select
                  id="nextOfKinCity"
                  value={payLoad?.nextOfKinCity || ""}
                  onChange={(val) => {
                    handleSelectChange(val, "nextOfKinCity");
                  }}
                  showSearch
                  className="w3-col"
                  style={{ width: "100%", height: "50px" }}
                >
                  <Option value="">Select Next of Kin City</Option>
                  {city.map((item: string, index: number) => (
                    <Option key={index} value={item}>
                      {item}
                    </Option>
                  ))}
                </Select>
              </div>
            </div> */}

            {/* Select Next of Kin Address */}
            <div className="w3-col">
              <div className="w3-col w3-margin-bottom w3-padding">
                <span className="inputTopName">
                  Next of Kin Address (Optional)
                </span>
                <Input
                  id="nextOfKinAddress"
                  name="nextOfKinAddress"
                  value={payLoad?.nextOfKinAddress || ""}
                  onChange={handleInputChange}
                  className="w3-col inputField"
                  style={{ width: "100%", height: "50px" }}
                  placeholder="Next of Kin Address"
                />
              </div>
            </div>
          </div>

          {/* Contract Information */}
          <div className="grid mb-4 pb-2 pt-2 mt-4  border-b">
            <h3 className="font-semibold"> Contract Information</h3>

            {/* Job Title */}
            <div className="w3-col">
              <div className="w3-col w3-padding">
                <div>
                  <span>
                    Job Title <span className="w3-text-red">*</span>
                  </span>
                </div>
                <Input
                  required
                  name="jobTitle"
                  value={payLoad?.jobTitle}
                  onChange={handleInputChange}
                  type="text"
                  className="w3-col inputField"
                  placeholder="Job Title"
                  style={{ width: "100%", height: "50px" }}
                />
              </div>
            </div>

            {/* Contract Type */}
            <div className="w3-col">
              <div className="w3-col w3-padding">
                <div>
                  <span>
                    Employment Contract Type{" "}
                    <span className="w3-text-red">*</span>
                  </span>
                </div>
                <select
                  id="contractType"
                  value={payLoad?.contractType || ""}
                  onChange={handleInputChange}
                  className="w3-col w3-round w3-white w3-border w3-padding"
                  style={{ width: "100%", height: "50px" }}
                >
                  <option value="">Select Contract Type</option>
                  {contractTypeListData.map((item, index: number) => (
                    <option key={index} value={item?.id}>
                      {item?.title}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {/* Location Type */}
            <div className="w3-col">
              <div className="w3-col w3-padding">
                <div>
                  <span>
                    Employment Location Type{" "}
                    <span className="w3-text-red">*</span>
                  </span>
                </div>
                <select
                  id="locationType"
                  value={payLoad?.locationType || ""}
                  onChange={handleInputChange}
                  className="w3-col w3-round w3-white w3-border w3-padding"
                  style={{ width: "100%", height: "50px" }}
                >
                  <option value="">Select Location Type</option>
                  {locationTypeListData.map((item, index: number) => (
                    <option key={index} value={item?.id}>
                      {item?.title}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {/* Job Department */}
            <div className="w3-col">
              <div className="w3-col w3-padding">
                <div>
                  <span>
                    Job Department <span className="w3-text-red">*</span>
                  </span>
                </div>
                <select
                  id="jobDepartment"
                  value={payLoad?.jobDepartment || ""}
                  onChange={handleInputChange}
                  className="w3-col w3-round w3-white w3-border w3-padding"
                  style={{ width: "100%", height: "50px" }}
                >
                  <option value="">select Job Department</option>
                  {departmentListData.map((item, index: number) => (
                    <option key={index} value={item?.id}>
                      {item?.title}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {/* Position */}
            <div className="w3-col">
              <div className="w3-col w3-padding">
                <div>
                  <span>
                    Position <span className="w3-text-red">*</span>
                  </span>
                </div>
                <select
                  id="position"
                  value={payLoad?.position || ""}
                  onChange={handleInputChange}
                  className="w3-col w3-round w3-white w3-border w3-padding"
                  style={{
                    width: "100%",
                    height: "50px",
                  }}
                >
                  <option value="">Select Position</option>
                  {jobRankListData.map((item, index: number) => (
                    <option key={index} value={item?.id}>
                      {item?.title}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {/* Select Start Date */}
            <div className="w3-col w3-padding">
              <div>
                <span>
                  Start Date <span className="w3-text-red">*</span>
                </span>
              </div>
              <Input
                required
                name="startDate"
                value={payLoad?.startDate}
                onChange={handleInputChange}
                type={"datetime-local"}
                className="w3-col inputField"
                placeholder="Start Date"
                style={{ width: "100%", height: "50px" }}
              />
            </div>
          </div>

          {/* Banking Details  */}
          <div className="grid mb-4 pb-2 pt-2 mt-4  border-b">
            <h3 className="font-semibold"> Banking Details </h3>

            {/* Account Number */}
            <div className="w3-col">
              <div className="w3-col w3-margin-bottom w3-padding">
                <div>
                  <span>
                    Account Number <span className="w3-text-red">*</span>
                  </span>
                </div>
                <Input
                  required
                  name="accountNumber"
                  value={payLoad?.accountNumber}
                  onChange={handleInputChange}
                  type="text"
                  className="w3-col inputField"
                  placeholder="Account Number"
                  style={{ width: "100%", height: "50px" }}
                />
              </div>
            </div>

            {/* Account Name */}
            <div className="w3-col">
              <div className="w3-col w3-margin-bottom w3-padding">
                <div>
                  <span>
                    Account Name <span className="w3-text-red">*</span>
                  </span>
                </div>
                <Input
                  required
                  name="accountName"
                  value={payLoad?.accountName || ""}
                  onChange={handleInputChange}
                  type="text"
                  className="w3-col inputField"
                  placeholder="Account Name"
                  style={{ width: "100%", height: "50px" }}
                />
              </div>
            </div>

            {/* Bank Name */}
            <div className="w3-col">
              <div className="w3-col w3-margin-bottom w3-padding">
                <div>
                  <span>
                    Bank Name <span className="w3-text-red">*</span>
                  </span>
                </div>
                <Input
                  required
                  name="bankName"
                  value={payLoad?.bankName || ""}
                  onChange={handleInputChange}
                  type="text"
                  className="w3-col inputField"
                  placeholder="Bank Name"
                  style={{ width: "100%", height: "50px" }}
                />
              </div>
            </div>
          </div>

          {/* Button Here */}
          <div className="w3-col">
            <div className="w3-col w3-border-blue w3-padding w3-container w3-round-xlarge w3-right-align">
              <button
                className="w3-btn sitePrimaryButton w3-round-large"
                disabled={formLoading}
              >
                {!formLoading ? (
                  "Add Employee"
                ) : (
                  <LoadingOutlined rev={undefined} />
                )}
              </button>
            </div>
          </div>
        </form>
      </>
    </div>
  );
};

export default HrAddEmployeeForm;
