import {
  AreaChartOutlined,
  AuditOutlined,
  BankOutlined,
  ClockCircleOutlined,
  CopyOutlined,
  DashboardOutlined,
  ExclamationCircleFilled,
  HeartOutlined,
  LineChartOutlined,
  LogoutOutlined,
  ScheduleOutlined,
  SettingOutlined,
  SolutionOutlined,
  UnorderedListOutlined,
  UserAddOutlined,
  UsergroupAddOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Modal } from "antd";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IAuthType } from "../../../apiservice/authService.type";
import { useAppSelector } from "../../../Redux/reduxCustomHook";
import { RootState } from "../../../Redux/store";
import { appZIndex } from "../../../utils/appconst";
import "./Sidebar.css";

type ISideBarType = {
  onLinkChange?: () => void;
  onUserType?: string;
};

const Sidebar: React.FC<ISideBarType> = ({ onLinkChange }) => {
  const location = useLocation();
  const [currentUrlPath, setCurrentUrlPath] = useState("");
  const [menu, setMenu] = useState<IMenuType[]>([]);
  const { confirm } = Modal;

  const authData: IAuthType = useAppSelector(
    (state: RootState) => state?.AuthData
  );

  const navigate = useNavigate();

  type IMenuType = {
    icon: string | any;
    url: string;
    title: string;
    onClick?: () => void;
  };

  //Logout Confirmation
  const showLogoutConfirm = () => {
    confirm({
      title: "Are you sure you want to logout from this application.",
      icon: <ExclamationCircleFilled rev={undefined} />,
      content: "",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      zIndex: appZIndex.modal,
      onOk() {
        navigate("/hr/logout");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const menuGeneral: IMenuType[] = [
    {
      icon: <DashboardOutlined rev={undefined} />,
      url: "/",
      title: "HR Cloud",
    },
    {
      icon: <UserAddOutlined rev={undefined} />,
      url: "/hr/employee-management",
      title: "Employee Database",
    },
    {
      icon: <ScheduleOutlined />,
      url: "/hr/leave-management",
      title: "Leave Management",
    },
    {
      icon: <ClockCircleOutlined />,
      url: "/hr/time-attendance",
      title: "Time And Attendance",
    },
    {
      icon: <SolutionOutlined />,
      url: "/hr/training",
      title: "Trainings",
    },
    {
      icon: <LineChartOutlined />,
      url: "/hr/analytics",
      title: "Analytics",
    },
    {
      icon: <UsergroupAddOutlined />,
      url: "/hr/recruitment",
      title: "Recruitment",
    },
    {
      icon: <AreaChartOutlined />,
      url: "/hr/performance",
      title: "Performance Manager",
    },
    {
      icon: <BankOutlined />,
      url: "/hr/payroll",
      title: "Payroll",
    },
    {
      icon: <HeartOutlined />,
      url: "/hr/employee-engagement",
      title: "Employment Engagement",
    },
    {
      icon: <SettingOutlined />,
      url: "/hr/settings",
      title: "Settings",
    },
    {
      icon: <UnorderedListOutlined />,
      url: "/hr/more-services",
      title: "More service",
    },
    {
      icon: <LogoutOutlined rev={undefined} />,
      url: "/publisher/logout",
      title: "Logout",
      onClick: () => {
        showLogoutConfirm();
      },
    },
  ];

  const menuHRManger: IMenuType[] = [
    {
      icon: <DashboardOutlined rev={undefined} />,
      url: "/",
      title: "HR Cloud",
    },
    {
      icon: <UserAddOutlined rev={undefined} />,
      url: "/hr/employee-management",
      title: "Employee Database",
    },
    {
      icon: <ScheduleOutlined />,
      url: "/hr/leave-management",
      title: "Leave Management",
    },
    {
      icon: <ClockCircleOutlined />,
      url: "/hr/time-attendance",
      title: "Time And Attendance",
    },
    {
      icon: <SolutionOutlined />,
      url: "/hr/training",
      title: "Trainings",
    },
    {
      icon: <LineChartOutlined />,
      url: "/hr/analytics",
      title: "Analytics",
    },
    {
      icon: <UsergroupAddOutlined />,
      url: "/hr/recruitment",
      title: "Recruitment",
    },
    {
      icon: <AreaChartOutlined />,
      url: "/hr/performance",
      title: "Performance Manager",
    },
    {
      icon: <BankOutlined />,
      url: "/hr/payroll",
      title: "Payroll",
    },
    {
      icon: <HeartOutlined />,
      url: "/hr/employee-engagement",
      title: "Employment Engagement",
    },
    {
      icon: <SettingOutlined />,
      url: "/hr/settings",
      title: "Settings",
    },
    {
      icon: <UnorderedListOutlined />,
      url: "/hr/more-services",
      title: "More service",
    },
    {
      icon: <LogoutOutlined rev={undefined} />,
      url: "/publisher/logout",
      title: "Logout",
      onClick: () => {
        showLogoutConfirm();
      },
    },
  ];

  const menuEmployeePortal: IMenuType[] = [
    {
      icon: <DashboardOutlined rev={undefined} />,
      url: "/hr/employee-portal",
      title: "HR Employee",
    },
    {
      icon: <ScheduleOutlined />,
      url: "/hr/leave-management*",
      title: "Leave Management",
    },
    {
      icon: <ClockCircleOutlined />,
      url: "/hr/time-attendance*",
      title: "Time Sheet",
    },
    {
      icon: <SolutionOutlined />,
      url: "/hr/training*",
      title: "Trainings",
    },
    {
      icon: <AreaChartOutlined />,
      url: "/hr/performance",
      title: "Performance Manager",
    },
    {
      icon: <HeartOutlined />,
      url: "/hr/employee-engagement*",
      title: "Employment Engagement",
    },
    {
      icon: <UserOutlined />,
      url: "/hr/profile*",
      title: "My Employee Profile",
    },
    {
      icon: <UnorderedListOutlined />,
      url: "/hr/more-services",
      title: "More service",
    },
    {
      icon: <LogoutOutlined rev={undefined} />,
      url: "/publisher/logout",
      title: "Logout",
      onClick: () => {
        showLogoutConfirm();
      },
    },
  ];

  const menuAccountant: IMenuType[] = [
    {
      icon: <DashboardOutlined rev={undefined} />,
      url: "/hr/accountant",
      title: "HR Accountant",
    },
    {
      icon: <CopyOutlined rev={undefined} />,
      url: "/hr/accountantBenefitManager",
      title: "Benefit Management",
    },
    {
      icon: <AuditOutlined />,
      url: "/hr/accountantTaxManagement",
      title: "Tax Manager",
    },
    {
      icon: <UnorderedListOutlined />,
      url: "/hr/more-services",
      title: "More service",
    },
    {
      icon: <LogoutOutlined rev={undefined} />,
      url: "/publisher/logout",
      title: "Logout",
      onClick: () => {
        showLogoutConfirm();
      },
    },
  ];

  useEffect(() => {
    // execute on location change
    setCurrentUrlPath(location.pathname);
    if (onLinkChange) {
      onLinkChange();
    }
    updateMenuType();
  }, [location, authData]);

  const updateMenuType = () => {
    setMenu(menuGeneral);
  };

  return (
    <>
      <div className="w3-col">
        <div className="fixedHeaderSideBar w3-padding w3-border-bottom w3-hide-small w3-hide-medium w3-center w3-margin-bottom navbarcontainerwrapper">
          <Link to={"/"}>
            <span>
              <img
                style={{ width: "80px" }}
                src={`${process.env.PUBLIC_URL + "/images/logo.png"}`}
                alt=""
              />
            </span>
          </Link>
        </div>

        <div
          className="w3-col w3-hide-small w3-hide-medium"
          style={{ height: "80px" }}
        >
          <br />
        </div>

        <div className="w3-col  w3-padding">
          <span className="siteBorderPrimaryColor w3-large "> Workk HRM </span>
        </div>

        <div className="w3-col w3-padding w3-margin-top">
          {menu.map((item: IMenuType, index: number) => (
            <div key={index}>
              {item.onClick ? (
                <button
                  onClick={item.onClick}
                  className={
                    "w3-btn botton " +
                    (currentUrlPath === item.url ? "buttonSelected" : "")
                  }
                >
                  <span
                    className={
                      "buttonText " +
                      (currentUrlPath === item.url
                        ? " buttonTextSelected "
                        : "")
                    }
                  >
                    {item.icon}
                    &nbsp;{item.title}
                  </span>
                </button>
              ) : (
                <Link
                  key={index}
                  className={
                    "w3-btn botton " +
                    (currentUrlPath === item.url ? "buttonSelected" : "")
                  }
                  to={item.url}
                >
                  <span
                    className={
                      "buttonText " +
                      (currentUrlPath === item.url
                        ? " buttonTextSelected "
                        : "")
                    }
                  >
                    {item.icon}
                    &nbsp;{item.title}
                  </span>
                </Link>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Sidebar;
