import { LoadingOutlined } from "@ant-design/icons";
import { Select, Input } from "antd";
import { useState } from "react";
import { sampleApiCall } from "../../../../apiservice/authService";
import useFormatApiRequest from "../../../../hooks/formatApiRequest";
import "../hrEmployeeManagementComp.css";
const { Option } = Select;

type IHrEmployeeEditGeneralInfoForm = {
  onFormSuccess?: () => void;
  onFormFailure?: (err?: string) => void;
};

export const HrEmployeeEditGeneralInfoForm: React.FC<
  IHrEmployeeEditGeneralInfoForm
> = ({ onFormFailure, onFormSuccess }) => {
  const [loadApi, setLoadApi] = useState(false);
  const [payLoad, setpayLoad] = useState<any>(null);
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [loadAuth, setLoadAuth] = useState(false);

  // Use to collect Input change Change
  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setpayLoad((values) => ({ ...values, [name]: value }));
  };

  const handleSelectChange = (value, name) => {
    setpayLoad((values) => ({ ...values, [name]: value }));
  };

  // Use to Submit Form
  const handleSubmit = (event: any) => {
    event.preventDefault();
    setLoadApi(true);
    setFormLoading(true);
  };

  // A custom hook to Process Form
  const result = useFormatApiRequest(
    () => sampleApiCall(payLoad),
    loadApi,
    () => {
      setLoadApi(false);
    },
    () => {
      processFormApi();
    }
  );

  // Process Api
  const processFormApi = async () => {
    if (result.httpState === "SUCCESS") {
      setFormLoading(false);
      setLoadAuth(true);
      if (onFormSuccess) {
        onFormSuccess();
      }
      // Handle Success Here
    } else if (result.httpState === "ERROR") {
      setFormLoading(false);
      if (onFormFailure) {
        onFormFailure(result.data?.response?.data?.message || result.errorMsg);
        alert(result.data?.response?.data?.message || result.errorMsg);
      }
      //Handle Error Here
    }
  };

  return (
    <div>
      <>
        <form onSubmit={handleSubmit}>
          {/* Select Department */}
          <div className="w3-col">
            <div className="w3-col w3-margin-bottom w3-padding">
              <div>
                <span>
                  Select Department <span className="w3-text-red">*</span>
                </span>
              </div>
              <Select
                id="department"
                value={payLoad?.employee || ""}
                onChange={(val) => {
                  handleSelectChange(val, "department");
                }}
                showSearch
                className="w3-col w3-round-xlarge"
                style={{ width: "100%", height: "50px" }}
              >
                <Option value="">Select Department</Option>
                <Option value="itsupport">IT Support</Option>
              </Select>
            </div>
          </div>

          {/* Employment Type */}
          <div className="w3-col">
            <div className="w3-col w3-margin-bottom  w3-padding">
              <div>
                <span>
                  Employment Type <span className="w3-text-red">*</span>
                </span>
              </div>
              <Select
                id="employmentType"
                value={payLoad?.employmentType || ""}
                onChange={(val) => {
                  handleSelectChange(val, "employmentType");
                }}
                showSearch
                className="w3-col w3-round-xlarge"
                style={{ width: "100%", height: "50px" }}
              >
                <Option value="">Select Employment Type</Option>
                <Option value="remote">Remote</Option>
                <Option value="onsite">Onsite</Option>
                <Option value="parttime">Part Time</Option>
                <Option value="fulltime">Full Time</Option>
              </Select>
            </div>
          </div>

          {/* Marital Status */}
          <div className="w3-col">
            <div className="w3-col w3-padding">
              <div>
                <span>
                  Marital Status <span className="w3-text-red">*</span>
                </span>
              </div>
              <Select
                id="maritalStatus"
                value={payLoad?.maritalStatus || ""}
                onChange={(val) => {
                  handleSelectChange(val, "maritalStatus");
                }}
                showSearch
                className="w3-col w3-round-xlarge"
                style={{ width: "100%", height: "50px" }}
              >
                <Option value="">Select Marital Status</Option>
                <Option value="single">Single</Option>
                <Option value="married">Married</Option>
                <Option value="divorced">Divorced</Option>
                <Option value="widowed">Widowed</Option>
              </Select>
            </div>
          </div>

          {/* Select Start Date */}
          <div className="w3-col w3-padding">
            <div>
              <span>
                Start Date <span className="w3-text-red">*</span>
              </span>
            </div>
            <Input
              required
              name="startDate"
              value={payLoad?.startDate}
              onChange={handleInputChange}
              type={"datetime-local"}
              className="w3-col inputField"
              placeholder="Start Date"
              style={{ width: "100%", height: "50px" }}
            />
          </div>

          {/* Select Birthday */}
          <div className="w3-col w3-padding">
            <div>
              <span>
                Birthday <span className="w3-text-red">*</span>
              </span>
            </div>
            <Input
              required
              name="birthday"
              value={payLoad?.birthday}
              onChange={handleInputChange}
              type="date"
              className="w3-col inputField"
              placeholder="Birthday"
              style={{ width: "100%", height: "50px" }}
            />
          </div>

          {/* Button Here */}
          <div className="w3-col">
            <div className="w3-col w3-border-blue w3-padding w3-container w3-round-xlarge w3-right-align">
              <button
                className="w3-btn sitePrimaryButton w3-round-large"
                disabled={formLoading}
              >
                {!formLoading ? (
                  "Save Changes"
                ) : (
                  <LoadingOutlined rev={undefined} />
                )}
              </button>
            </div>
          </div>
        </form>
      </>
    </div>
  );
};

export default HrEmployeeEditGeneralInfoForm;
