import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import useAuth, {
  USER_REFRESH_TOKEN_KEY,
  USER_TOKEN_KEY,
} from "./hooks/useAuth";
import { ProtectedRoute } from "./pages/ProtectedRoute/ProtectedRoutePage";
import UserPagesRoutes from "./pages/hrProfileDashboard/userPagesRoute";
import Nopage from "./pages/Nopage/Nopage";
import { useState, useEffect } from "react";
import { storePlainString } from "./utils/localStorage";
import { IAccessTokenType } from "./apiservice/authService.type";
import useFormatApiRequest from "./hooks/formatApiRequest";
import { authRefreshSessionToken } from "./apiservice/authService";

const App = () => {
  const [loadAuth, setLoadAuth] = useState(false);
  const [loadAccessApi, setLoadAccessApi] = useState(false);
  const [token, setToken] = useState(null);

  // Use to refresh the Auth State
  const authState = useAuth(loadAuth, () => {
    setLoadAuth(false);
  });

  useEffect(() => {
    // Function to handle messages from the iframe
    const receiveMessage = (event) => {
      // Verify the origin of the message for security (adjust the origin as necessary)
      if (event.origin !== process.env.REACT_APP_DOMAIN_URL) {
        return;
      }

      // Check the message type
      if (event.data.type === "refreshToken") {
        // Get the token from the message
        const receivedToken = event.data?.token;
        // console.log("Received token from iframe:", receivedToken);

        if (!receivedToken) {
          alert("You are not authorised to view this page.");
          document.location = process.env.REACT_APP_DOMAIN_URL || "";
          return;
        }

        // Update the state with the received token
        storePlainString(USER_REFRESH_TOKEN_KEY, receivedToken);
        setToken(receivedToken);
        setLoadAccessApi(true);
      } else {
      }
    };

    // Add an event listener for messages
    window.addEventListener("message", receiveMessage, false);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("message", receiveMessage, false);
    };
  }, []);

  // A custom hook to get Access Token
  const result = useFormatApiRequest(
    () => authRefreshSessionToken({ refreshToken: token }),
    loadAccessApi,
    () => {
      setLoadAccessApi(false);
    },
    () => {
      processApi();
    }
  );

  // Process Api
  const processApi = async () => {
    if (result.httpState === "SUCCESS") {
      console.log(result.data);
      const accessTokenResult: IAccessTokenType = result.data;
      storePlainString(
        USER_TOKEN_KEY,
        accessTokenResult?.data?.accessToken || ""
      );
      setLoadAuth(true);
    }
  };

  return (
    <>
      <div style={{ display: "none" }}>
        <h1>Parent Frame</h1>
        <iframe
          id="myIframe"
          src={process.env.REACT_APP_TOKEN_URL}
          width="600"
          height="200"
          title="Iframe Example"
        ></iframe>
      </div>
      {token && (
        <BrowserRouter>
          <Routes>
            <Route index element={<UserPagesRoutes />} />
            <Route path="hr/*" element={<UserPagesRoutes></UserPagesRoutes>} />
            <Route path="*" element={<Nopage />} />
          </Routes>
        </BrowserRouter>
      )}
    </>
  );
};

export default App;
