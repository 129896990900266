import { LoadingOutlined } from "@ant-design/icons";
import { Input } from "antd";
import { useState, useEffect } from "react";
import { hrAddDepartment, hrAddLocationType } from "../../../../apiservice/authService";
import useFormatApiRequest from "../../../../hooks/formatApiRequest";
import "../HrSettings.css";

type IHrLocationTypeForm = {
  onFormSuccess?: () => void;
  onFormFailure?: (err?: string) => void;
};

export const HrLocationTypeForm: React.FC<IHrLocationTypeForm> = ({
  onFormFailure,
  onFormSuccess,
}) => {
  const [loadApi, setLoadApi] = useState(false);
  const [payLoad, setpayLoad] = useState<any>(null);
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [loadAuth, setLoadAuth] = useState(false);

  // Use to collect Input change Change
  const handleInputChange = (event) => {
    const name = event.target.name || event.target.id;
    const value = event.target.value;
    setpayLoad((values) => ({ ...values, [name]: value }));
  };

  // useEffect(() => {

  // }, []);

  // Use to Submit Form
  const handleSubmit = (event: any) => {
    event.preventDefault();
    setLoadApi(true);
    setFormLoading(true);
  };

  // A custom hook to Process Form
  const result = useFormatApiRequest(
    () => hrAddLocationType(payLoad),
    loadApi,
    () => {
      setLoadApi(false);
    },
    () => {
      processFormApi();
    }
  );

  // Process Api
  const processFormApi = async () => {
    if (result.httpState === "SUCCESS") {
      setFormLoading(false);
      setLoadAuth(true);
      if (onFormSuccess) {
        onFormSuccess();
      }
      alert("Location Type Has been added.");
      // Handle Success Here
    } else if (result.httpState === "ERROR") {
      setFormLoading(false);
      if (onFormFailure) {
        onFormFailure(
          result.data?.response?.data?.errors?.[0].message ||
            result.data?.response?.data?.message ||
            result.errorMsg
        );
      }
      alert(
        result.data?.response?.data?.errors?.[0].message ||
          result.data?.response?.data?.message ||
          result.errorMsg
      );
      //Handle Error Here
    }
  };

  return (
    <div>
      <>
        <form onSubmit={handleSubmit}>
          {/* Form */}
          <div className="grid mb-4 pb-2 pt-2 mt-4 border-t border-b">
            {/* Title */}
            <div className="w3-col">
              <div className="w3-col w3-margin-bottom w3-padding">
                <div>
                  <span>
                    Location Type Title <span className="w3-text-red">*</span>
                  </span>
                </div>
                <Input
                  required
                  name="title"
                  value={payLoad?.title}
                  onChange={handleInputChange}
                  type="text"
                  className="w3-col inputField"
                  placeholder="Title"
                  style={{ width: "100%", height: "50px" }}
                />
              </div>
            </div>

            {/* Description */}
            <div className="w3-col">
              <div className="w3-col w3-margin-bottom w3-padding">
                <div>
                  <span>Description (Optional)</span>
                </div>
                <Input
                  name="description"
                  value={payLoad?.description}
                  onChange={handleInputChange}
                  type="text"
                  className="w3-col inputField"
                  placeholder="Description"
                  style={{ width: "100%", height: "50px" }}
                />
              </div>
            </div>
          </div>

          {/* Button Here */}
          <div className="w3-col">
            <div className="w3-col w3-border-blue w3-padding w3-container w3-round-xlarge w3-right-align">
              <button
                className="w3-btn sitePrimaryButton w3-round-large"
                disabled={formLoading}
              >
                {!formLoading ? "Saved" : <LoadingOutlined rev={undefined} />}
              </button>
            </div>
          </div>
        </form>
      </>
    </div>
  );
};

export default HrLocationTypeForm;
