import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Button, notification, Pagination, Result } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { hrGetEmploymentTypes } from "../../../../apiservice/authService";
import { IHrEmploymentTypeData } from "../../../../apiservice/authService.type";
import useFormatApiRequest from "../../../../hooks/formatApiRequest";
import { ILoadState } from "../../../../utils/loading.utils.";
import "../HrSettings.css";
type NotificationType = "success" | "info" | "warning" | "error";

type IHrEmployementContractList = {
  externalFilter?: any;
  initialDefaultFilter?: any;
  hidePagination?: boolean;
};

export const HrEmployementContractList: React.FC<
  IHrEmployementContractList
> = ({ externalFilter, initialDefaultFilter, hidePagination = false }) => {
  const [employmentTypesLoadState, setEmploymentTypesLoadState] =
    useState<ILoadState>("loading");
  const [loadEmploymentTypesData, setLoadEmploymentTypesData] = useState(true);
  const [employmentTypesDefaultFilter, setEmploymentTypesDefaultFilter] =
    useState(initialDefaultFilter || { page: 1, perPage: 10 });
  const [tableData, setTableData] = useState<IHrEmploymentTypeData[]>([]);
  const [api, contextHolder] = notification.useNotification();

  // Pagination Constant/Variables
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const perPage = 10;

  // Use Effect to reload API when External Filter has changed
  useEffect(() => {
    if (externalFilter) {
      setCurrentPage(1);
      setEmploymentTypesDefaultFilter({
        ...employmentTypesDefaultFilter,
        ...externalFilter,
        page: 1,
      });
      setLoadEmploymentTypesData(true);
      setEmploymentTypesLoadState("loading");
    }
  }, [externalFilter]);

  // A custom hook to Load All EmploymentTypes
  const EmploymentTypesDataResult = useFormatApiRequest(
    () => hrGetEmploymentTypes(employmentTypesDefaultFilter),
    loadEmploymentTypesData,
    () => {
      setLoadEmploymentTypesData(false);
    },
    () => {
      processEmploymentTypesResult();
    }
  );

  // Process The Current EmploymentTypes Data Result
  const processEmploymentTypesResult = async () => {
    if (EmploymentTypesDataResult.httpState === "SUCCESS") {
      setTableData(EmploymentTypesDataResult.data?.data || []);
      setTotalItems(EmploymentTypesDataResult.data?.meta?.total || 1);
      setEmploymentTypesLoadState("completed");
    } else if (EmploymentTypesDataResult.httpState === "ERROR") {
      setEmploymentTypesLoadState("error");
    } else if (EmploymentTypesDataResult.httpState === "LOADING") {
      setEmploymentTypesLoadState("loading");
    }
  };

  // Show Notification
  const openNotificationWithIcon = (
    type: NotificationType,
    message: string,
    description: string,
    background?: string
  ) => {
    api[type]({
      message,
      description,
      placement: "bottomRight",
      style: { background },
    });
  };

  // Table Configuration
  const columns: ColumnsType<IHrEmploymentTypeData> = [
    {
      title: "Employment Type",
      dataIndex: "name",
      key: "name",
      render: (text, records, index) => (
        <span>
          <span>
            <b className="w3-small"> {records.title} </b>
          </span>
        </span>
      ),
    },
    {
      title: "Caption",
      dataIndex: "caption",
      key: "caption",
      render: (text, records, index) => (
        <span>{"Short Description of Employment Type..."}</span>
      ),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "select",
      fixed: "right",
      width: 120,
      render: (text, records, index) => (
        <div className="w3-center">
          <span style={{ zoom: 1.2 }}>
            <EditOutlined />
          </span>
          &nbsp; &nbsp;
          <span style={{ zoom: 1.2 }}>
            <DeleteOutlined />
          </span>
        </div>
      ),
    },
  ];

  // Use to Control Pagination On Change Event
  const onPageChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setEmploymentTypesDefaultFilter({
      ...employmentTypesDefaultFilter,
      page: page,
      perPage: pageSize,
    });
    setLoadEmploymentTypesData(true);
  };

  return (
    <>
      {/* " The context is use to hold the notification from ant design" */}
      {contextHolder}

      {/* " Show Loading Error" */}
      {employmentTypesLoadState === "error" && (
        <div className="w3-col w3-padding-bottom">
          <Result
            status="500"
            title="Error"
            subTitle="Sorry, something went wrong, it could be a network Related error"
            extra={
              <Button
                onClick={() => setLoadEmploymentTypesData(true)}
                type="primary"
              >
                Reload
              </Button>
            }
          />
        </div>
      )}

      {/* " Show Loading Table" */}
      {employmentTypesLoadState !== "error" && (
        <>
          <div className="w3-col w3-margin-top">
            <Table
              loading={employmentTypesLoadState === "loading"}
              rowKey="id"
              size="small"
              columns={columns}
              scroll={{ x: 950 }}
              dataSource={tableData}
              pagination={{ hideOnSinglePage: true }}
            />
          </div>

          {!hidePagination && (
            <div className="w3-col w3-margin-top">
              <Pagination
                current={currentPage || 1}
                onChange={onPageChange}
                pageSize={perPage}
                total={totalItems}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default HrEmployementContractList;
