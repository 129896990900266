import { Button, notification, Pagination, Result, Tag } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { sampleApiCall } from "../../../../apiservice/authService";
import useFormatApiRequest from "../../../../hooks/formatApiRequest";
import { convertToShortDate } from "../../../../utils/date.utils";
import { ILoadState } from "../../../../utils/loading.utils.";
import "../hrEmployeeRecruitment.css";
type NotificationType = "success" | "info" | "warning" | "error";

type IHRJobList = {
  externalFilter?: any;
  initialDefaultFilter?: any;
  hidePagination?: boolean;
};

export const HRJobList: React.FC<IHRJobList> = ({
  externalFilter,
  initialDefaultFilter,
  hidePagination = true,
}) => {
  const [joblistsLoadState, setJoblistsLoadState] =
    useState<ILoadState>("loading");
  const [loadJoblistssData, setLoadJoblistssData] = useState(true);
  const [joblistsDefaultFilter, setJoblistsDefaultFilter] = useState(
    initialDefaultFilter || { page: 1, perPage: 10 }
  );
  const [tableData, setTableData] = useState<any[]>([]);
  const [api, contextHolder] = notification.useNotification();

  // Pagination Constant/Variables
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const perPage = 10;

  // Use Effect to reload API when External Filter has changed
  useEffect(() => {
    if (externalFilter) {
      setCurrentPage(1);
      setJoblistsDefaultFilter({
        ...joblistsDefaultFilter,
        ...externalFilter,
        page: 1,
      });
      setLoadJoblistssData(true);
      setJoblistsLoadState("loading");
    }
  }, [externalFilter]);

  // A custom hook to Load All Joblistss
  const JoblistsDataResult = useFormatApiRequest(
    () => sampleApiCall(joblistsDefaultFilter),
    loadJoblistssData,
    () => {
      setLoadJoblistssData(false);
    },
    () => {
      processJoblistssResult();
    }
  );

  // Process The Current Joblists Data Result
  const processJoblistssResult = async () => {
    if (JoblistsDataResult.httpState === "SUCCESS") {
      setTableData(JoblistsDataResult.data?.data || [{}, {}, {}, {}]);
      setTotalItems(JoblistsDataResult.data?.meta?.total || 1);
      setJoblistsLoadState("completed");
    } else if (JoblistsDataResult.httpState === "ERROR") {
      setJoblistsLoadState("error");
    } else if (JoblistsDataResult.httpState === "LOADING") {
      setJoblistsLoadState("loading");
    }
  };

  // Show Notification
  const openNotificationWithIcon = (
    type: NotificationType,
    message: string,
    description: string,
    background?: string
  ) => {
    api[type]({
      message,
      description,
      placement: "bottomRight",
      style: { background },
    });
  };

  // Table Configuration
  const columns: ColumnsType<any> = [
    {
      title: "Job Title",
      dataIndex: "jobTitle",
      key: "jobTitle",
      render: (text, records, index) => <span>{"Junior Software Eng..."}</span>,
    },
    {
      title: "Job Type",
      dataIndex: "jobType",
      key: "joyType",
      render: (text, records, index) => <span>{"Full Time"}</span>,
    },
    {
      title: "Location Type",
      dataIndex: "locationType",
      key: "locationType",
      render: (text, records, index) => <span>{"Remote"}</span>,
    },
    {
      title: "Department",
      dataIndex: "status",
      key: "category",
      render: (text, records, index) => (
        <Tag className="tagText" color={"blue"}>
          {"Developer"}
        </Tag>
      ),
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, records, index) => (
        <Tag className="tagText" color={"green"}>
          {"Open"}
        </Tag>
      ),
    },
    {
      title: "Date",
      dataIndex: "dateModified",
      key: "dateModified",
      render: (text) => <span>{convertToShortDate(text)}</span>,
    },
  ];

  // Use to Control Pagination On Change Event
  const onPageChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setJoblistsDefaultFilter({
      ...joblistsDefaultFilter,
      page: page,
      perPage: pageSize,
    });
    setLoadJoblistssData(true);
  };

  return (
    <>
      {/* " The context is use to hold the notification from ant design" */}
      {contextHolder}

      {/* " Show Loading Error" */}
      {joblistsLoadState === "error" && (
        <div className="w3-col w3-padding-bottom">
          <Result
            status="500"
            title="Error"
            subTitle="Sorry, something went wrong, it could be a network Related error"
            extra={
              <Button onClick={() => setLoadJoblistssData(true)} type="primary">
                Reload
              </Button>
            }
          />
        </div>
      )}

      {/* " Show Loading Table" */}
      {joblistsLoadState !== "error" && (
        <>
          <div className="w3-grid">
            <div className="w3-border w3-padding w3-round-xlarge">
              <p className="sitePrimaryColor w3-large"></p>
              <Table
                loading={joblistsLoadState === "loading"}
                rowKey="id"
                size="small"
                columns={columns}
                dataSource={tableData}
                scroll={{ x: 950 }}
                pagination={{ hideOnSinglePage: true }}
              />
            </div>
          </div>

          {!hidePagination && (
            <div className="w3-col w3-margin-top">
              <Pagination
                current={currentPage || 1}
                onChange={onPageChange}
                pageSize={perPage}
                total={totalItems}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default HRJobList;
