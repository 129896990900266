import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Button, notification, Pagination, Result } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { hrGetDepartments } from "../../../../apiservice/authService";
import { IHrDepartmentTypeData } from "../../../../apiservice/authService.type";
import useFormatApiRequest from "../../../../hooks/formatApiRequest";
import { ILoadState } from "../../../../utils/loading.utils.";
import "../HrSettings.css";
type NotificationType = "success" | "info" | "warning" | "error";

type IHrDepartmentList = {
  externalFilter?: any;
  initialDefaultFilter?: any;
  hidePagination?: boolean;
};

export const HrDepartmentList: React.FC<IHrDepartmentList> = ({
  externalFilter,
  initialDefaultFilter,
  hidePagination = false,
}) => {
  const [departmentsLoadState, setDepartmentsLoadState] =
    useState<ILoadState>("loading");
  const [loadDepartmentssData, setLoadDepartmentssData] = useState(true);
  const [departmentsDefaultFilter, setDepartmentsDefaultFilter] = useState(
    initialDefaultFilter || { page: 1, perPage: 10 }
  );
  const [tableData, setTableData] = useState<IHrDepartmentTypeData[]>([]);
  const [api, contextHolder] = notification.useNotification();

  // Pagination Constant/Variables
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const perPage = 10;

  // Use Effect to reload API when External Filter has changed
  useEffect(() => {
    if (externalFilter) {
      setCurrentPage(1);
      setDepartmentsDefaultFilter({
        ...departmentsDefaultFilter,
        ...externalFilter,
        page: 1,
      });
      setLoadDepartmentssData(true);
      setDepartmentsLoadState("loading");
    }
  }, [externalFilter]);

  // A custom hook to Load All Departmentss
  const DepartmentsDataResult = useFormatApiRequest(
    () => hrGetDepartments(departmentsDefaultFilter),
    loadDepartmentssData,
    () => {
      setLoadDepartmentssData(false);
    },
    () => {
      processDepartmentssResult();
    }
  );

  // Process The Current Departments Data Result
  const processDepartmentssResult = async () => {
    if (DepartmentsDataResult.httpState === "SUCCESS") {
      setTableData(DepartmentsDataResult.data?.data || []);
      setTotalItems(DepartmentsDataResult.data?.meta?.total || 1);
      setDepartmentsLoadState("completed");
    } else if (DepartmentsDataResult.httpState === "ERROR") {
      setDepartmentsLoadState("error");
    } else if (DepartmentsDataResult.httpState === "LOADING") {
      setDepartmentsLoadState("loading");
    }
  };

  // Show Notification
  const openNotificationWithIcon = (
    type: NotificationType,
    message: string,
    description: string,
    background?: string
  ) => {
    api[type]({
      message,
      description,
      placement: "bottomRight",
      style: { background },
    });
  };

  // Table Configuration
  const columns: ColumnsType<IHrDepartmentTypeData> = [
    {
      title: "Department Name",
      dataIndex: "name",
      key: "name",
      render: (text, records, index) => (
        <span>
          <span>
            <b className="w3-small"> {records.title} </b>
          </span>
        </span>
      ),
    },
    {
      title: "Caption",
      dataIndex: "caption",
      key: "caption",
      render: (text, records, index) => (
        <span>{"Short Description of Department..."}</span>
      ),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "select",
      fixed: "right",
      width: 120,
      render: (text, records, index) => (
        <div className="w3-center">
          <span style={{ zoom: 1.2 }}>
            <EditOutlined />
          </span>
          &nbsp; &nbsp;
          <span style={{ zoom: 1.2 }}>
            <DeleteOutlined />
          </span>
        </div>
      ),
    },
  ];

  // Use to Control Pagination On Change Event
  const onPageChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setDepartmentsDefaultFilter({
      ...departmentsDefaultFilter,
      page: page,
      perPage: pageSize,
    });
    setLoadDepartmentssData(true);
  };

  return (
    <>
      {/* " The context is use to hold the notification from ant design" */}
      {contextHolder}

      {/* " Show Loading Error" */}
      {departmentsLoadState === "error" && (
        <div className="w3-col w3-padding-bottom">
          <Result
            status="500"
            title="Error"
            subTitle="Sorry, something went wrong, it could be a network Related error"
            extra={
              <Button
                onClick={() => setLoadDepartmentssData(true)}
                type="primary"
              >
                Reload
              </Button>
            }
          />
        </div>
      )}

      {/* " Show Loading Table" */}
      {departmentsLoadState !== "error" && (
        <>
          <div className="w3-col w3-margin-top">
            <Table
              loading={departmentsLoadState === "loading"}
              rowKey="id"
              size="small"
              columns={columns}
              scroll={{ x: 950 }}
              dataSource={tableData}
              pagination={{ hideOnSinglePage: true }}
            />
          </div>

          {!hidePagination && (
            <div className="w3-col w3-margin-top">
              <Pagination
                current={currentPage || 1}
                onChange={onPageChange}
                pageSize={perPage}
                total={totalItems}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default HrDepartmentList;
