import { Breadcrumb, Tabs, TabsProps } from "antd";
import { Link } from "react-router-dom";
import HrDepartmentListWrapper from "./hrSettingsList/hrDepartmentListWrapper";
import HrEmployementContractListWrapper from "./hrSettingsList/hrEmployementContractListWrapper";
import HrLocationTypeListWrapper from "./hrSettingsList/hrLocationTypeListWrapper";
import HrRankListWrapper from "./hrSettingsList/hrRankListWrapper";
import HrTeamListWrapper from "./hrSettingsList/hrTeamListWrapper";
import "./HrSettings.css";

export const HrSettingsWrapper = () => {
  const onChange = (key: string) => {
    console.log(key);
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Department",
      children: (
        <>
          <HrDepartmentListWrapper></HrDepartmentListWrapper>
        </>
      ),
    },
    {
      key: "2",
      label: "Employment Type",
      children: (
        <>
          <HrEmployementContractListWrapper></HrEmployementContractListWrapper>
        </>
      ),
    },
    {
      key: "3",
      label: "Employment Location",
      children: (
        <>
          <HrLocationTypeListWrapper></HrLocationTypeListWrapper>
        </>
      ),
    },
    {
      key: "4",
      label: "Position/Ranks",
      children: (
        <>
          <HrRankListWrapper></HrRankListWrapper>
        </>
      ),
    },
    {
      key: "5",
      label: "Teams",
      children: (
        <>
          <HrTeamListWrapper></HrTeamListWrapper>
        </>
      ),
    },
    {
      key: "6",
      label: "Holidays",
      children: <></>,
    },
  ];
  return (
    <>
      <div className="w3-col" style={{ minHeight: "400px" }}>
        <div className="w3-col" style={{ marginTop: "20px" }}>
          <div className="w3-container">
            <div className="w3-content">
              <div className="w3-col w3-margin-bottom">
                <Breadcrumb
                  items={[
                    {
                      title: (
                        <span className="siteBorderPrimaryColor w3-large">
                          <Link to={"/hr"}>HRCLOUD</Link>
                        </span>
                      ),
                    },
                    {
                      title: (
                        <span className="siteBorderPrimaryColor w3-large">
                          Settings
                        </span>
                      ),
                    },
                  ]}
                />
                <br />
                <p>
                  Borem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                  vulputate libero et velit interdum, ac aliquet odio mattis.
                </p>
              </div>

              <div className="w3-col  w3-margin-bottom">
                <Tabs
                  tabBarStyle={{
                    borderBottom: "1px solid #0D333E",
                    fontSize: "16px",
                  }}
                  defaultActiveKey="1"
                  items={items}
                  onChange={onChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HrSettingsWrapper;
