import { LoadingOutlined } from "@ant-design/icons";
import { Select, Input } from "antd";
import { useState } from "react";
import { sampleApiCall } from "../../../../apiservice/authService";
import { places } from "../../../../apiservice/places";
import { IPlace } from "../../../../apiservice/places.type";
import useFormatApiRequest from "../../../../hooks/formatApiRequest";
import "../hrEmployeeManagementComp.css";
const { Option } = Select;

type IHrEmployeeEditBankInfoForm = {
  onFormSuccess?: () => void;
  onFormFailure?: (err?: string) => void;
};

export const HrEmployeeEditBankInfoForm: React.FC<
  IHrEmployeeEditBankInfoForm
> = ({ onFormFailure, onFormSuccess }) => {
  const [loadApi, setLoadApi] = useState(false);
  const [payLoad, setpayLoad] = useState<any>(null);
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [loadAuth, setLoadAuth] = useState(false);

  // Use to collect Input change Change
  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setpayLoad((values) => ({ ...values, [name]: value }));
  };

  const handleSelectChange = (value, name) => {
    setpayLoad((values) => ({ ...values, [name]: value }));
  };

  // Use to Submit Form
  const handleSubmit = (event: any) => {
    event.preventDefault();
    setLoadApi(true);
    setFormLoading(true);
  };

  // A custom hook to Process Form
  const result = useFormatApiRequest(
    () => sampleApiCall(payLoad),
    loadApi,
    () => {
      setLoadApi(false);
    },
    () => {
      processFormApi();
    }
  );

  // Process Api
  const processFormApi = async () => {
    if (result.httpState === "SUCCESS") {
      setFormLoading(false);
      setLoadAuth(true);
      if (onFormSuccess) {
        onFormSuccess();
      }
      // Handle Success Here
    } else if (result.httpState === "ERROR") {
      setFormLoading(false);
      if (onFormFailure) {
        onFormFailure(result.data?.response?.data?.message || result.errorMsg);
        alert(result.data?.response?.data?.message || result.errorMsg);
      }
      //Handle Error Here
    }
  };

  return (
    <div>
      <>
        <form onSubmit={handleSubmit}>
          {/* Account Number */}
          <div className="w3-col">
            <div className="w3-col w3-margin-bottom w3-padding">
              <div>
                <span>
                  Account Number <span className="w3-text-red">*</span>
                </span>
              </div>
              <Input
                required
                name="accountNumber"
                value={payLoad?.accountNumber}
                onChange={handleInputChange}
                type="text"
                className="w3-col inputField"
                placeholder="Account Number"
                style={{ width: "100%", height: "50px" }}
              />
            </div>
          </div>

          {/* Account Name */}
          <div className="w3-col">
            <div className="w3-col w3-margin-bottom w3-padding">
              <div>
                <span>
                  Account Name <span className="w3-text-red">*</span>
                </span>
              </div>
              <Input
                required
                name="accountName"
                value={payLoad?.accountName || ""}
                onChange={handleInputChange}
                type="text"
                className="w3-col inputField"
                placeholder="Account Name"
                style={{ width: "100%", height: "50px" }}
              />
            </div>
          </div>

          {/* Bank Name */}
          <div className="w3-col">
            <div className="w3-col w3-margin-bottom w3-padding">
              <div>
                <span>
                  Bank Name <span className="w3-text-red">*</span>
                </span>
              </div>
              <Input
                required
                name="bankName"
                value={payLoad?.bankName || ""}
                onChange={handleInputChange}
                type="text"
                className="w3-col inputField"
                placeholder="Bank Name"
                style={{ width: "100%", height: "50px" }}
              />
            </div>
          </div>

          {/* Button Here */}
          <div className="w3-col">
            <div className="w3-col w3-border-blue w3-padding w3-container w3-round-xlarge w3-right-align">
              <button
                className="w3-btn sitePrimaryButton w3-round-large"
                disabled={formLoading}
              >
                {!formLoading ? (
                  "Save Changes"
                ) : (
                  <LoadingOutlined rev={undefined} />
                )}
              </button>
            </div>
          </div>
        </form>
      </>
    </div>
  );
};

export default HrEmployeeEditBankInfoForm;
