import { LoadingOutlined } from "@ant-design/icons";
import { Select, Input } from "antd";
import { useState } from "react";
import { sampleApiCall } from "../../../../apiservice/authService";
import useFormatApiRequest from "../../../../hooks/formatApiRequest";
import "../hrEmployeeManagementComp.css";
const { Option } = Select;

type IHrEmployeeEditContractInfoForm = {
  onFormSuccess?: () => void;
  onFormFailure?: (err?: string) => void;
};

export const HrEmployeeEditContractInfoForm: React.FC<
  IHrEmployeeEditContractInfoForm
> = ({ onFormFailure, onFormSuccess }) => {
  const [loadApi, setLoadApi] = useState(false);
  const [payLoad, setpayLoad] = useState<any>(null);
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [loadAuth, setLoadAuth] = useState(false);

  // Use to collect Input change Change
  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setpayLoad((values) => ({ ...values, [name]: value }));
  };

  const handleSelectChange = (value, name) => {
    setpayLoad((values) => ({ ...values, [name]: value }));
  };

  // Use to Submit Form
  const handleSubmit = (event: any) => {
    event.preventDefault();
    setLoadApi(true);
    setFormLoading(true);
  };

  // A custom hook to Process Form
  const result = useFormatApiRequest(
    () => sampleApiCall(payLoad),
    loadApi,
    () => {
      setLoadApi(false);
    },
    () => {
      processFormApi();
    }
  );

  // Process Api
  const processFormApi = async () => {
    if (result.httpState === "SUCCESS") {
      setFormLoading(false);
      setLoadAuth(true);
      if (onFormSuccess) {
        onFormSuccess();
      }
      // Handle Success Here
    } else if (result.httpState === "ERROR") {
      setFormLoading(false);
      if (onFormFailure) {
        onFormFailure(result.data?.response?.data?.message || result.errorMsg);
        alert(result.data?.response?.data?.message || result.errorMsg);
      }
      //Handle Error Here
    }
  };

  return (
    <div>
      <>
        <form onSubmit={handleSubmit}>
          {/* Job Title */}
          <div className="w3-col">
            <div className="w3-col w3-padding">
              <div>
                <span>
                  Job Title <span className="w3-text-red">*</span>
                </span>
              </div>
              <Input
                required
                name="jobTitle"
                value={payLoad?.jobTitle}
                onChange={handleInputChange}
                type="text"
                className="w3-col inputField"
                placeholder="Job Title"
                style={{ width: "100%", height: "50px" }}
              />
            </div>
          </div>

          {/* Contract Type */}
          <div className="w3-col">
            <div className="w3-col w3-padding">
              <div>
                <span>
                  Employment Contract Type{" "}
                  <span className="w3-text-red">*</span>
                </span>
              </div>
              <select
                id="contractType"
                value={payLoad?.contractType || ""}
                onChange={(val) => {
                  handleSelectChange(val, "contractType");
                }}
                className="w3-col w3-round w3-white w3-border w3-padding"
                style={{ width: "100%", height: "50px" }}
              >
                <option value="">Select Contract Type</option>
                <option value="full-time">Full-time</option>
                <option value="part-time">Part-time</option>
                <option value="freelance">Freelance</option>
                <option value="temporary">Temporary</option>
                <option value="contractor">Contractor</option>
              </select>
            </div>
          </div>

          {/* Location Type */}
          <div className="w3-col">
            <div className="w3-col w3-padding">
              <div>
                <span>
                  Employment Location Type{" "}
                  <span className="w3-text-red">*</span>
                </span>
              </div>
              <select
                id="locationType"
                value={payLoad?.locationType || ""}
                onChange={(val) => {
                  handleSelectChange(val, "locationType");
                }}
                className="w3-col w3-round w3-white w3-border w3-padding"
                style={{ width: "100%", height: "50px" }}
              >
                <option value="">Select Location Type</option>
                <option value="remote">Remote</option>
                <option value="on-site">On-site</option>
                <option value="hybrid">Hybrid</option>
              </select>
            </div>
          </div>

          {/* Job Department */}
          <div className="w3-col">
            <div className="w3-col w3-padding">
              <div>
                <span>
                  Job Department <span className="w3-text-red">*</span>
                </span>
              </div>
              <select
                id="jobDepartment"
                value={payLoad?.jobDepartment || ""}
                onChange={(val) => {
                  handleSelectChange(val, "jobDepartment");
                }}
                className="w3-col w3-round w3-white w3-border w3-padding"
                style={{ width: "100%", height: "50px" }}
              >
                <option value="">select Job Department</option>
                <option value="sales">Sales</option>
                <option value="marketing">Marketing</option>
                <option value="engineering">Engineering</option>
                <option value="hr">Human Resources</option>
                <option value="finance">Finance</option>
              </select>
            </div>
          </div>

          {/* Position */}
          <div className="w3-col">
            <div className="w3-col w3-padding">
              <div>
                <span>
                  Position <span className="w3-text-red">*</span>
                </span>
              </div>
              <select
                id="position"
                value={payLoad?.position || ""}
                onChange={(val) => {
                  handleSelectChange(val, "position");
                }}
                className="w3-col w3-round w3-white w3-border w3-padding"
                style={{
                  width: "100%",
                  height: "50px",
                }}
              >
                <option value="">Select Position</option>
                <option value="ceo">CEO</option>
                <option value="manager">Manager</option>
                <option value="admin">Admin</option>
                <option value="seniorstaff">Senior Staff</option>
                <option value="juniorstaff">Junior Staff</option>
              </select>
            </div>
          </div>

          {/* Select Start Date */}
          <div className="w3-col w3-padding">
            <div>
              <span>
                Start Date <span className="w3-text-red">*</span>
              </span>
            </div>
            <Input
              required
              name="startDate"
              value={payLoad?.startDate}
              onChange={handleInputChange}
              type={"datetime-local"}
              className="w3-col inputField"
              placeholder="Start Date"
              style={{ width: "100%", height: "50px" }}
            />
          </div>

          {/* End Date */}
          <div className="w3-col w3-padding">
            <div>
              <span>
                End Date <span className="w3-text-red">*</span>
              </span>
            </div>
            <Input
              required
              name="endDate"
              value={payLoad?.endDate}
              onChange={handleInputChange}
              type={"datetime-local"}
              className="w3-col inputField"
              placeholder="End Date"
              style={{ width: "100%", height: "50px" }}
            />
          </div>

          {/* Button Here */}
          <div className="w3-col">
            <div className="w3-col w3-border-blue w3-padding w3-container w3-round-xlarge w3-right-align">
              <button
                className="w3-btn sitePrimaryButton w3-round-large"
                disabled={formLoading}
              >
                {!formLoading ? (
                  "Save Changes"
                ) : (
                  <LoadingOutlined rev={undefined} />
                )}
              </button>
            </div>
          </div>
        </form>
      </>
    </div>
  );
};

export default HrEmployeeEditContractInfoForm;
