import { LoadingOutlined } from "@ant-design/icons";
import { Select, Input } from "antd";
import { useState } from "react";
import { sampleApiCall } from "../../../../apiservice/authService";
import { places } from "../../../../apiservice/places";
import { IPlace } from "../../../../apiservice/places.type";
import useFormatApiRequest from "../../../../hooks/formatApiRequest";
import "../hrEmployeeManagementComp.css";
const { Option } = Select;

type IHrEmployeeEditEmergencyContactInfo = {
  onFormSuccess?: () => void;
  onFormFailure?: (err?: string) => void;
};

export const HrEmployeeEditEmergencyContactInfo: React.FC<
  IHrEmployeeEditEmergencyContactInfo
> = ({ onFormFailure, onFormSuccess }) => {
  const [loadApi, setLoadApi] = useState(false);
  const [payLoad, setpayLoad] = useState<any>(null);
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [loadAuth, setLoadAuth] = useState(false);

  const [country, setCountry] = useState<any[]>([]);
  const [city, setCity] = useState<any[]>([]);
  const [countryCode, setCountryCode] = useState<any[]>([]);
  const allPlace: IPlace[] = places as IPlace[];

  // Use to collect Input change Change
  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setpayLoad((values) => ({ ...values, [name]: value }));
  };

  const handleSelectChange = (value, name) => {
    setpayLoad((values) => ({ ...values, [name]: value }));
  };

  const updateCountry = () => {
    const countries = allPlace.map((place) => {
      return { name: place.emoji + " " + place.name, value: place.name };
    });

    const countriesCode = allPlace.map((place) => {
      return {
        name: place.emoji + " " + place.phone_code,
        value: place.phone_code,
      };
    });

    setCountry(countries);
    setCountryCode(countriesCode);
  };

  const updateState = (selectedCountry) => {
    let states;
    if (getCountryIndex(selectedCountry) === -1) {
      states = [];
    } else {
      states = allPlace[getCountryIndex(selectedCountry)].states.map(
        (state) => state.name
      );
    }

    setCity(states);
  };

  const getCountryIndex = (selectedCountry: string) => {
    console.log("Selected Country", selectedCountry);
    return country.findIndex((country) => selectedCountry === country?.value);
  };

  // Use to Submit Form
  const handleSubmit = (event: any) => {
    event.preventDefault();
    setLoadApi(true);
    setFormLoading(true);
  };

  // A custom hook to Process Form
  const result = useFormatApiRequest(
    () => sampleApiCall(payLoad),
    loadApi,
    () => {
      setLoadApi(false);
    },
    () => {
      processFormApi();
    }
  );

  // Process Api
  const processFormApi = async () => {
    if (result.httpState === "SUCCESS") {
      setFormLoading(false);
      setLoadAuth(true);
      if (onFormSuccess) {
        onFormSuccess();
      }
      // Handle Success Here
    } else if (result.httpState === "ERROR") {
      setFormLoading(false);
      if (onFormFailure) {
        onFormFailure(result.data?.response?.data?.message || result.errorMsg);
        alert(result.data?.response?.data?.message || result.errorMsg);
      }
      //Handle Error Here
    }
  };

  return (
    <div>
      <>
        <form onSubmit={handleSubmit}>
          {/* Emergency Full Name */}
          <div className="w3-col">
            <div className="w3-col w3-margin-bottom w3-padding">
              <div>
                <span>
                  Emergency Full Name <span className="w3-text-red">*</span>
                </span>
              </div>
              <Input
                required
                name="emergencyFullname"
                value={payLoad?.emergencyFullname}
                onChange={handleInputChange}
                type="text"
                className="w3-col inputField"
                placeholder="Emergency Full Name"
                style={{ width: "100%", height: "50px" }}
              />
            </div>
          </div>

          {/* Emergency Relationship */}
          <div className="w3-col">
            <div className="w3-col w3-margin-bottom w3-padding">
              <div>
                <span>
                  Emergency Relationship <span className="w3-text-red">*</span>
                </span>
              </div>
              <Input
                required
                name="emergencyRelationship"
                value={payLoad?.emergencyRelationship}
                onChange={handleInputChange}
                type="text"
                className="w3-col inputField"
                placeholder="Emergency Relationship"
                style={{ width: "100%", height: "50px" }}
              />
            </div>
          </div>

          {/* Emergency Email */}
          <div className="w3-col">
            <div className="w3-col w3-margin-bottom w3-padding">
              <div>
                <span>
                  Emergency Email <span className="w3-text-red">*</span>
                </span>
              </div>
              <Input
                required
                name="emergencyEmail"
                value={payLoad?.emergencyEmail}
                onChange={handleInputChange}
                type="email"
                className="w3-col inputField"
                placeholder="Emergency Email"
                style={{ width: "100%", height: "50px" }}
              />
            </div>
          </div>

          {/* Select Emergency Phone */}
          <div className="w3-col">
            <div className="w3-col w3-margin-bottom w3-padding">
              <span className="inputTopName">
                Emergency Phone Number <span className="w3-text-red"> * </span>
              </span>
              <div className="w3-col">
                <div className="w3-col" style={{ width: "120px" }}>
                  <Select
                    id="emergencyCountryCode"
                    showSearch
                    value={payLoad?.emergencyCountryCode || "+234"}
                    onChange={(val) => {
                      handleSelectChange(val, "emergencyCountryCode");
                    }}
                    className="w3-col"
                    placeholder="Country Code"
                    style={{ width: "100%", height: "50px" }}
                  >
                    <Option value="+234">+234</Option>
                    {countryCode.map((item: any, index: number) => (
                      <Option key={index} value={item?.value}>
                        {item?.name}
                      </Option>
                    ))}
                  </Select>
                </div>
                <div className="w3-rest">
                  <Input
                    required
                    name="emergencyPhoneNumber"
                    value={payLoad?.emergencyPhoneNumber || ""}
                    onChange={handleInputChange}
                    style={{ height: "50px" }}
                    className="w3-col inputField"
                    placeholder="Emergency Phone Number"
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Select Emergency Country */}
          <div className="w3-col">
            <div className="w3-col w3-margin-bottom w3-padding">
              <span className="inputTopName">
                {" "}
                Emergency Country (Optional)
              </span>
              <br />
              <Select
                id="emergencyCountry"
                value={payLoad?.emergencyCountry || ""}
                onChange={(val) => {
                  handleSelectChange(val, "emergencyCountry");
                }}
                showSearch
                className="w3-col"
                style={{ width: "100%", height: "50px" }}
              >
                <Option value="">Select Emergency Country</Option>
                {country.map((item: any, index: number) => (
                  <Option key={index} value={item?.value}>
                    {item?.name}
                  </Option>
                ))}
              </Select>
            </div>
          </div>

          {/* Select Emergency City */}
          <div className="w3-col">
            <div className="w3-col w3-margin-bottom w3-padding">
              <span className="inputTopName"> Emergency City (Optional)</span>
              <Select
                id="emergencyCity"
                value={payLoad?.emergencyCity || ""}
                onChange={(val) => {
                  handleSelectChange(val, "emergencyCity");
                }}
                showSearch
                className="w3-col"
                style={{ width: "100%", height: "50px" }}
              >
                <Option value="">Select Emergency City</Option>
                {city.map((item: string, index: number) => (
                  <Option key={index} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            </div>
          </div>

          {/* Select Emergency Address */}
          <div className="w3-col">
            <div className="w3-col w3-margin-bottom w3-padding">
              <span className="inputTopName">Emergency Address (Optional)</span>
              <Input
                id="emergencyAddress"
                name="emergencyAddress"
                value={payLoad?.emergencyAddress || ""}
                onChange={handleInputChange}
                className="w3-col inputField"
                style={{ width: "100%", height: "50px" }}
                placeholder="Emergency Address"
              />
            </div>
          </div>

          {/* Button Here */}
          <div className="w3-col">
            <div className="w3-col w3-border-blue w3-padding w3-container w3-round-xlarge w3-right-align">
              <button
                className="w3-btn sitePrimaryButton w3-round-large"
                disabled={formLoading}
              >
                {!formLoading ? (
                  "Save Changes"
                ) : (
                  <LoadingOutlined rev={undefined} />
                )}
              </button>
            </div>
          </div>
        </form>
      </>
    </div>
  );
};

export default HrEmployeeEditEmergencyContactInfo;
