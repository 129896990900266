import instance from "../utils/axios.wrapper";
import { convertObjToQueryParams } from "../utils/basic.utils";
import { IAuthType } from "./authService.type";

// Employees API

export const hrAddEmployee = async (body: any) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.post("api/hrm/v1/employees", body);
  const result: IAuthType = await data;
  return result;
};

export const hrGetEmployees = async (body?: any) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.get(
    `api/hrm/v1/employees${convertObjToQueryParams(body)}`
  );
  const result = await data;
  return result;
};

export const hrGetEmployeeSingle = async (id: number | string) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.get(`api/hrm/v1/employees/${id}`);
  const result = await data;
  return result;
};

export const hrUpdateEmployee = async (body: any) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.patch("api/hrm/v1/employees", body);
  const result = await data;
  return result;
};

export const hrDeleteEmployee = async (id: number | string) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.delete(`api/hrm/v1/employees/${id}`);
  const result = await data;
  return result;
};
