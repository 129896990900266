import { Button, Empty, notification, Result, Spin } from "antd";
import { useEffect, useState } from "react";
import { sampleApiCall } from "../../../apiservice/authService";
import useFormatApiRequest from "../../../hooks/formatApiRequest";
import useAuth from "../../../hooks/useAuth";
import { useAppDispatch } from "../../../Redux/reduxCustomHook";
import { ILoadState } from "../../../utils/loading.utils.";
import GeneralCharts from "../../Sharedcomponents/Charts/GeneralCharts";
import "./hrEmployeeManagementComp.css";
type NotificationType = "success" | "info" | "warning" | "error";

type IHrEmployeePerformanceChart = {
  externalFilter?: any;
  initialDefaultFilter?: any;
};

export const HrEmployeePerformanceChart: React.FC<
  IHrEmployeePerformanceChart
> = ({ externalFilter, initialDefaultFilter }) => {
  const [hrEmployeeChartLoadState, setHrEmployeeChartLoadState] =
    useState<ILoadState>("loading");
  const [loadHrEmployeeChartData, setLoadHrEmployeeChartData] = useState(true);
  const [hrEmployeeChartDefaultFilter, setHrEmployeeChartDefaultFilter] =
    useState(initialDefaultFilter || {});
  const [tableData, setTableData] = useState<any[]>([]);
  const [api, contextHolder] = notification.useNotification();
  const dispatch = useAppDispatch();
  const [loadAuth, setLoadAuth] = useState(false);

  const [chartDataGeneral, setChartDataGeneral] = useState<any>(null);
  const [chartDataAverage, setChartDataAverage] = useState<any>(null);

  // Use to refresh the Auth State
  const authState = useAuth(loadAuth, () => {
    setLoadAuth(false);
  });

  // Use Effect to reload API when External Filter has changed
  useEffect(() => {
    if (externalFilter) {
      setHrEmployeeChartDefaultFilter({
        ...hrEmployeeChartDefaultFilter,
        ...externalFilter,
      });
      setLoadHrEmployeeChartData(true);
      setHrEmployeeChartLoadState("loading");
      setLoadAuth(true);
    }
  }, [externalFilter]);

  // A custom hook to Load All HrEmployeeChart Details
  const hrEmployeeChartDataResult = useFormatApiRequest(
    () => sampleApiCall(hrEmployeeChartDefaultFilter),
    loadHrEmployeeChartData,
    () => {
      setLoadHrEmployeeChartData(false);
    },
    () => {
      processHrEmployeeChartResult();
    }
  );

  // Process The Current HrEmployeeChart Data Result
  const processHrEmployeeChartResult = async () => {
    if (hrEmployeeChartDataResult.httpState === "SUCCESS") {
      // setTableData(hrEmployeeChartDataResult.data?.data || [{}]);
      setChartDataGeneral({
        labels: [
          "Attendance",
          "Punctuality",
          "Impact",
          "Productivity",
          "Development",
        ],
        datasets: [
          {
            label: "Performance Chart",
            data: [90, 88, 80, 80, 79],
            backgroundColor: [
              "#0A8543",
              "#38181A",
              "#6F4439",
              "#EA5317",
              "#64697A",
            ],
            borderWidth: 0,
          },
        ],
      });

      setChartDataAverage({
        labels: ["Average Performance", "Empty"],
        datasets: [
          {
            label: "Performance Chart",
            data: [80, 20],
            backgroundColor: ["#181F38", "#E7E6E6"],
            borderWidth: 0,
          },
        ],
      });
      setHrEmployeeChartLoadState("completed");
    } else if (hrEmployeeChartDataResult.httpState === "ERROR") {
      setHrEmployeeChartLoadState("error");
    } else if (hrEmployeeChartDataResult.httpState === "LOADING") {
      setHrEmployeeChartLoadState("loading");
    }
  };

  // Show Notification
  const openNotificationWithIcon = (
    type: NotificationType,
    message: string,
    description: string,
    background?: string
  ) => {
    api[type]({
      message,
      description,
      placement: "bottomRight",
      style: { background },
    });
  };

  return (
    <>
      {/* " The context is use to hold the notification from ant design" */}
      {contextHolder}
      <div>
        {/* " Show Loading Indicator" */}
        {hrEmployeeChartLoadState === "loading" && (
          <div
            className="w3-col w3-center w3-padding-bottom"
            style={{ paddingTop: "100px" }}
          >
            <Spin size="large" />
          </div>
        )}

        {/* " Show Loading Error" */}
        {hrEmployeeChartLoadState === "error" && (
          <div className="w3-col w3-padding-bottom">
            <Result
              status="500"
              title={<span className="w3-text-white">Error</span>}
              subTitle={
                <span className="w3-text-white">
                  Sorry, something went wrong, it could be a network Related
                  error
                </span>
              }
              extra={
                <Button
                  onClick={() => setLoadHrEmployeeChartData(true)}
                  type="primary"
                >
                  Reload
                </Button>
              }
            />
          </div>
        )}

        {/* " Show No data" */}
        {hrEmployeeChartLoadState === "noData" && (
          <div className="w3-margin-top">
            <Empty></Empty>
          </div>
        )}

        {/* " Show No data" */}
        {hrEmployeeChartLoadState === "completed" && (
          <>
            <div className="w3-col w3-margin-top">
              <div className="grid gap-2 grid-cols-1 lg:grid-cols-3">
                <div className="lg:col-span-2">
                  <GeneralCharts
                    title="General Perfomance"
                    data={chartDataGeneral}
                  ></GeneralCharts>
                </div>

                <div>
                  <GeneralCharts
                    title="Average Performance"
                    type="doughnut"
                    data={chartDataAverage}
                  ></GeneralCharts>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
