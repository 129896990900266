import { Modal } from "antd";
import { useState } from "react";
import "../hrEmployeeManagementComp.css";
import { IAuthType } from "../../../../apiservice/authService.type";
import { useAppSelector } from "../../../../Redux/reduxCustomHook";
import { RootState } from "../../../../Redux/store";
import { appZIndex } from "../../../../utils/appconst";
import HrEmployeeEditContractInfoForm from "../hrEmployeeforms/HrEmployee-Edit-Contract-Info-form";
import HrEmployeeHistoryList from "./hrEmployeeHistoryList";

export const HrEmployeeHistoryListWrappper = () => {
  const [payLoadFilter, setpayLoadFilter] = useState<any>({});
  const [externalFilter, setExternalFilter] = useState<any>({});

  const [showModal, setShowModal] = useState<boolean>(false);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCancelModal = () => {
    setShowModal(false);
  };

  const authData: IAuthType = useAppSelector(
    (state: RootState) => state?.AuthData
  );

  // Use to collect Change
  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    // console.log(value)
    setpayLoadFilter((values) => ({ ...values, [name]: value }));
    if (name === "status") {
      setExternalFilter({ ...externalFilter, status: value });
    }
  };

  const handleButtonClick = (value) => {
    setpayLoadFilter((values) => ({ ...values }));
  };

  // Use to Update Filter
  const updateFilter = () => {
    setExternalFilter({ ...payLoadFilter });
  };

  return (
    <>
      <div className="w3-col">
        <div className="w3-container">
          {/* Title */}
          <div className="w3-col w3-margin-top">
            <div
              className="w3-col l6 s6 m6"
              style={{ padding: "5px", paddingTop: "15px" }}
            >
              &nbsp;
            </div>

            <div
              className="w3-col l6 s6 m6 w3-right-align"
              style={{ padding: "5px", paddingTop: "15px" }}
            >
              <button
                onClick={handleShowModal}
                className="w3-btn w3-round-large sitePrimaryButton"
              >
                Add Employee History
              </button>
            </div>
          </div>

          <div className="w3-col w3-margin-top">
            <HrEmployeeHistoryList></HrEmployeeHistoryList>
          </div>
        </div>
      </div>

      {/* Modal */}
      <Modal
        zIndex={appZIndex.modal}
        open={showModal}
        title={"Add Employement History"}
        onCancel={handleCancelModal}
        width={700}
        footer={[<p style={{ minHeight: "00px" }}></p>]}
      >
        <div style={{ maxHeight: "400px", overflow: "scroll" }}>
          <HrEmployeeEditContractInfoForm></HrEmployeeEditContractInfoForm>
        </div>
      </Modal>
    </>
  );
};

export default HrEmployeeHistoryListWrappper;
