import { SearchOutlined } from "@ant-design/icons";
import { useState } from "react";
import "./hrEmployeeManagementComp.css";
import { HrEmployeePerformanceChart } from "./hrEmployeeDetailCard-Performance-Chart";

export const HrEmployeePerformanceChartWrapper = () => {
  const [payLoadFilter, setpayLoadFilter] = useState<any>({});
  const [externalFilter, setExternalFilter] = useState<any>({});
  // Use to collect Change
  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setpayLoadFilter((values) => ({ ...values, [name]: value }));
  };

  // Use to Update Filter
  const updateFilter = () => {
    setExternalFilter({ ...payLoadFilter });
  };

  return (
    <>
      <div className="w3-container w3-margin-top">
        <div className="w3-content">
          {/* Date */}
          <div className="w3-col w3-margin-top">
            <div className="w3-col l2 s2 m2" style={{ padding: "2px" }}>
              <span className="w3-small w3-text-black"> Period</span>
              <select
                name="type"
                value={payLoadFilter?.type || ""}
                onChange={handleInputChange}
                className="w3-col w3-input w3-border w3-round-large w3-text-black"
              >
                <option value="default">This Month</option>
                <option value="custom"> Custom Period </option>
              </select>
            </div>

            {payLoadFilter?.type === "custom" && (
              <>
                <div className="w3-col l4 s4 m4" style={{ padding: "5px" }}>
                  <span className="w3-small w3-text-black"> From </span>
                  <input
                    name="startDate"
                    value={payLoadFilter?.startDate || ""}
                    onChange={handleInputChange}
                    className="w3-col w3-input w3-border w3-round-large w3-text-black"
                    placeholder="Date"
                    type={"date"}
                  />
                </div>
                <div className="w3-col l4 s4 m4" style={{ padding: "5px" }}>
                  <span className="w3-small w3-text-black"> To </span>
                  <input
                    name="endDate"
                    value={payLoadFilter?.endDate || ""}
                    onChange={handleInputChange}
                    className="w3-col w3-input w3-border w3-round-large w3-text-black"
                    placeholder="Date"
                    type={"date"}
                  />
                </div>
                <div className="w3-col l2 s2 m2" style={{ padding: "5px" }}>
                  <span className="w3-small">&nbsp;</span>
                  <button
                    onClick={updateFilter}
                    className="w3-btn w3-round sitePrimaryButton w3-col"
                  >
                    Apply
                  </button>
                </div>
              </>
            )}
          </div>

          {/* Component */}
          <div className="w3-col l12 s12 m12" style={{ padding: "2px" }}>
            <HrEmployeePerformanceChart></HrEmployeePerformanceChart>
          </div>
        </div>
      </div>
    </>
  );
};

export default HrEmployeePerformanceChartWrapper;
