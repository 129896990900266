import { LoadingOutlined } from "@ant-design/icons";
import { Input, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useState } from "react";
import { sampleApiCall } from "../../../../apiservice/authService";
import useFormatApiRequest from "../../../../hooks/formatApiRequest";
import "../hrEmployeeRecruitment.css";
const { Option } = Select;

type IHrRecruitmentSetupForm = {
  onFormSuccess?: () => void;
  onFormFailure?: (err?: string) => void;
};

export const HrRecruitmentSetupForm: React.FC<IHrRecruitmentSetupForm> = ({
  onFormFailure,
  onFormSuccess,
}) => {
  const [loadApi, setLoadApi] = useState(false);
  const [payLoad, setpayLoad] = useState<any>(null);
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [loadAuth, setLoadAuth] = useState(false);

  // Use to collect Input change Change
  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setpayLoad((values) => ({ ...values, [name]: value }));
  };

  const handleSelectChange = (value, name) => {
    setpayLoad((values) => ({ ...values, [name]: value }));
  };

  // Use to Submit Form
  const handleSubmit = (event: any) => {
    event.preventDefault();
    setLoadApi(true);
    setFormLoading(true);
  };

  // A custom hook to Process Form
  const result = useFormatApiRequest(
    () => sampleApiCall(payLoad),
    loadApi,
    () => {
      setLoadApi(false);
    },
    () => {
      processFormApi();
    }
  );

  // Process Api
  const processFormApi = async () => {
    if (result.httpState === "SUCCESS") {
      setFormLoading(false);
      setLoadAuth(true);
      if (onFormSuccess) {
        onFormSuccess();
      }
      // Handle Success Here
    } else if (result.httpState === "ERROR") {
      setFormLoading(false);
      if (onFormFailure) {
        onFormFailure(result.data?.response?.data?.message || result.errorMsg);
        alert(result.data?.response?.data?.message || result.errorMsg);
      }
      //Handle Error Here
    }
  };

  return (
    <div>
      <>
        <form onSubmit={handleSubmit}>
          {/* Job Title */}
          <div className="w3-col">
            <div className="w3-col">
              <div className="w3-col w3-padding">
                <div>
                  <span>
                    Job Title <span className="w3-text-red">*</span>
                  </span>
                </div>
                <Input
                  required
                  name="jobTitle"
                  value={payLoad?.jobTitle}
                  onChange={handleInputChange}
                  type="text"
                  className="w3-col inputField"
                  placeholder="Job Title"
                  style={{ width: "100%", height: "50px" }}
                />
              </div>
            </div>
          </div>

          {/* Job Type */}
          <div className="w3-col">
            <div className="w3-col w3-padding">
              <div>
                <span>
                  Job Type <span className="w3-text-red">*</span>
                </span>
              </div>
              <Select
                id="jobType"
                value={payLoad?.jobType || ""}
                onChange={(val) => {
                  handleSelectChange(val, "jobType");
                }}
                showSearch
                className="w3-col w3-round-xlarge"
                style={{ width: "100%", height: "50px" }}
              >
                <Option value="">Select Job Type</Option>
                <Option value="remote">Remote</Option>
                <Option value="onsite">Onsite</Option>
                <Option value="parttime">Part Time</Option>
                <Option value="fulltime">Full Time</Option>
              </Select>
            </div>
          </div>

          {/* Job Description */}
          <div className="w3-col">
            <div className="w3-col">
              <div className="w3-col w3-padding">
                <div>
                  <span>Job Description</span>
                </div>
                <TextArea
                  name="jobDescription"
                  value={payLoad?.jobDescription}
                  onChange={handleInputChange}
                  className="w3-col inputField"
                  placeholder="Description of Job"
                  style={{ width: "100%", height: "80px", resize: "none" }}
                />
              </div>
            </div>
          </div>

          {/* Job Requirement */}
          <div className="w3-col">
            <div className="w3-col">
              <div className="w3-col w3-padding">
                <div>
                  <span>Job Requirement</span>
                </div>
                <TextArea
                  name="jobRequirement"
                  value={payLoad?.jobRequirement}
                  onChange={handleInputChange}
                  className="w3-col inputField"
                  placeholder="Description of Job Requirement"
                  style={{ width: "100%", height: "80px", resize: "none" }}
                />
              </div>
            </div>
          </div>

          {/* Job Location */}
          <div className="w3-col">
            <div className="w3-col">
              <div className="w3-col w3-padding">
                <div>
                  <span>
                    Job Location <span className="w3-text-red">*</span>
                  </span>
                </div>
                <Input
                  required
                  name="jobLocation"
                  value={payLoad?.jobLocation}
                  onChange={handleInputChange}
                  type="text"
                  className="w3-col inputField"
                  placeholder="Job Location"
                  style={{ width: "100%", height: "50px" }}
                />
              </div>
            </div>
          </div>

          {/* Deadline */}
          <div className="w3-col">
            <div className="w3-col">
              <div className="w3-col w3-padding">
                <div>
                  <span>
                    Deadline <span className="w3-text-red">*</span>
                  </span>
                </div>
                <Input
                  required
                  name="deadLine"
                  value={payLoad?.deadLine}
                  onChange={handleInputChange}
                  type="date"
                  className="w3-col inputField"
                  placeholder="Deadline"
                  style={{ width: "100%", height: "50px" }}
                />
              </div>
            </div>
          </div>

          {/* Button Here */}
          <div className="w3-col">
            <div className="w3-col w3-border-blue w3-padding w3-container w3-round-xlarge w3-right-align">
              <button
                className="w3-btn sitePrimaryButton w3-round-large"
                disabled={formLoading}
              >
                {!formLoading ? "Proceed" : <LoadingOutlined rev={undefined} />}
              </button>
            </div>
          </div>
        </form>
      </>
    </div>
  );
};

export default HrRecruitmentSetupForm;
