import HrSettingsWrapper from "../../../components/HRUsersComp/hrSettings/HrSettingsWrapper";
import "../userPage.css";

export const HRSettingsPage = () => {
  return (
    <>
      <HrSettingsWrapper></HrSettingsWrapper>
    </>
  );
};

export default HRSettingsPage;
