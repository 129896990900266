import { Button, Empty, notification, Result, Spin } from "antd";
import { useEffect, useState } from "react";
import { sampleApiCall } from "../../../apiservice/authService";
import useFormatApiRequest from "../../../hooks/formatApiRequest";
import useAuth from "../../../hooks/useAuth";
import { useAppDispatch } from "../../../Redux/reduxCustomHook";
import { ILoadState } from "../../../utils/loading.utils.";
import GeneralCharts from "../../Sharedcomponents/Charts/GeneralCharts";
import "./HrCloudDashboard.css";
type NotificationType = "success" | "info" | "warning" | "error";

type IHrDashboardEmployeeChart = {
  externalFilter?: any;
  initialDefaultFilter?: any;
};

export const HrDashboardEmployeeChart: React.FC<IHrDashboardEmployeeChart> = ({
  externalFilter,
  initialDefaultFilter,
}) => {
  const [hrEmployeeChartLoadState, setHrEmployeeChartLoadState] =
    useState<ILoadState>("loading");
  const [loadHrEmployeeChartData, setLoadHrEmployeeChartData] = useState(true);
  const [hrEmployeeChartDefaultFilter, setHrEmployeeChartDefaultFilter] =
    useState(initialDefaultFilter || {});
  const [tableData, setTableData] = useState<any[]>([]);
  const [api, contextHolder] = notification.useNotification();
  const dispatch = useAppDispatch();
  const [loadAuth, setLoadAuth] = useState(false);

  const [chartDataHeadCount, setChartDataHeadCount] = useState<any>(null);
  const [chartDataDepartment, setChartDataDepartment] = useState<any>(null);

  // Use to refresh the Auth State
  const authState = useAuth(loadAuth, () => {
    setLoadAuth(false);
  });

  // Use Effect to reload API when External Filter has changed
  useEffect(() => {
    if (externalFilter) {
      setHrEmployeeChartDefaultFilter({
        ...hrEmployeeChartDefaultFilter,
        ...externalFilter,
      });
      setLoadHrEmployeeChartData(true);
      setHrEmployeeChartLoadState("loading");
      setLoadAuth(true);
    }
  }, [externalFilter]);

  // A custom hook to Load All HrEmployeeChart Details
  const hrEmployeeChartDataResult = useFormatApiRequest(
    () => sampleApiCall(hrEmployeeChartDefaultFilter),
    loadHrEmployeeChartData,
    () => {
      setLoadHrEmployeeChartData(false);
    },
    () => {
      processHrEmployeeChartResult();
    }
  );

  // Process The Current HrEmployeeChart Data Result
  const processHrEmployeeChartResult = async () => {
    if (hrEmployeeChartDataResult.httpState === "SUCCESS") {
      // setTableData(hrEmployeeChartDataResult.data?.data || [{}]);
      setChartDataHeadCount({
        labels: ["January", "February", "March", "April", "May", "June"],
        datasets: [
          {
            label: "Head Count",
            data: [20, 20, 18, 22, 22, 21],
            backgroundColor: ["#0e7490"],
            borderWidth: 0,
          },
        ],
      });

      setChartDataDepartment({
        labels: ["Frontend Dev", "BackEnd Dev", "Projects", "Admin", "Others"],
        datasets: [
          {
            label: "Employee Department",
            data: [5, 7, 2, 3, 5],
            backgroundColor: [
              "#a16207",
              "#4d7c0f",
              "#a21caf",
              "#be185d",
              "#f0abfc",
            ],
            borderWidth: 0,
          },
        ],
      });
      setHrEmployeeChartLoadState("completed");
    } else if (hrEmployeeChartDataResult.httpState === "ERROR") {
      setHrEmployeeChartLoadState("error");
    } else if (hrEmployeeChartDataResult.httpState === "LOADING") {
      setHrEmployeeChartLoadState("loading");
    }
  };

  // Show Notification
  const openNotificationWithIcon = (
    type: NotificationType,
    message: string,
    description: string,
    background?: string
  ) => {
    api[type]({
      message,
      description,
      placement: "bottomRight",
      style: { background },
    });
  };

  return (
    <>
      {/* " The context is use to hold the notification from ant design" */}
      {contextHolder}
      <div>
        {/* " Show Loading Indicator" */}
        {hrEmployeeChartLoadState === "loading" && (
          <div
            className="w3-col w3-center w3-padding-bottom"
            style={{ paddingTop: "100px" }}
          >
            <Spin size="large" />
          </div>
        )}

        {/* " Show Loading Error" */}
        {hrEmployeeChartLoadState === "error" && (
          <div className="w3-col w3-padding-bottom">
            <Result
              status="500"
              title={<span className="w3-text-white">Error</span>}
              subTitle={
                <span className="w3-text-white">
                  Sorry, something went wrong, it could be a network Related
                  error
                </span>
              }
              extra={
                <Button
                  onClick={() => setLoadHrEmployeeChartData(true)}
                  type="primary"
                >
                  Reload
                </Button>
              }
            />
          </div>
        )}

        {/* " Show No data" */}
        {hrEmployeeChartLoadState === "noData" && (
          <div className="w3-margin-top">
            <Empty></Empty>
          </div>
        )}

        {/* " Show No data" */}
        {hrEmployeeChartLoadState === "completed" && (
          <>
            <div className="w3-col w3-margin-top">
              <div className="grid gap-2 grid-cols-1 lg:grid-cols-3">
                <div className="lg:col-span-2">
                  <GeneralCharts
                    title="Head Count"
                    data={chartDataHeadCount}
                  ></GeneralCharts>
                </div>

                <div>
                  <GeneralCharts
                    title="Department"
                    type="doughnut"
                    data={chartDataDepartment}
                  ></GeneralCharts>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
