import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";
import "./HrEmployeeEngagement.css";
import HrEmployeeEngagementMenu from "./HrEmployeeEngagementMenu";
import HrFeedBackListWrapper from "./HrEngagementLists/hrFeedBackListWrapper";

export const HrEmployeeEngagementWrapper = () => {
  return (
    <>
      <div className="w3-col" style={{ minHeight: "400px" }}>
        <div className="w3-col" style={{ marginTop: "20px" }}>
          <div className="w3-container">
            <div className="w3-content">
              <div className="w3-col w3-margin-bottom">
                <Breadcrumb
                    items={[
                      {
                        title: (
                          <span className="siteBorderPrimaryColor w3-large">
                            <Link to={"/hr"}>HRCLOUD</Link>
                          </span>
                        ),
                      },
                      {
                        title: (
                          <span className="siteBorderPrimaryColor w3-large">
                           Employee Engagement (FeedBack)
                          </span>
                        ),
                      },
                    ]}
                  />
                <br />
                <p>
                  Borem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                  vulputate libero et velit interdum, ac aliquet odio mattis.
                </p>
              </div>

              <HrEmployeeEngagementMenu></HrEmployeeEngagementMenu>

              <div className="w3-col w3-margin-bottom">
                <HrFeedBackListWrapper></HrFeedBackListWrapper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HrEmployeeEngagementWrapper;
