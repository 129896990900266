import { InboxOutlined } from "@ant-design/icons";
import { Select, Upload, UploadProps, message } from "antd";
import { useState } from "react";
import { sampleApiCall } from "../../../../apiservice/authService";
import useFormatApiRequest from "../../../../hooks/formatApiRequest";
import "../hrEmployeeManagementComp.css";
const { Option } = Select;

type IHrEmployeeUploadFileForm = {
  onFormSuccess?: () => void;
  onFormFailure?: (err?: string) => void;
};

export const HrEmployeeUploadFileForm: React.FC<IHrEmployeeUploadFileForm> = ({
  onFormFailure,
  onFormSuccess,
}) => {
  const [loadApi, setLoadApi] = useState(false);
  const [payLoad, setpayLoad] = useState<any>(null);
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [loadAuth, setLoadAuth] = useState(false);

  const { Dragger } = Upload;

  const props: UploadProps = {
    name: "file",
    multiple: true,
    action: "https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload",
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  // Use to collect Input change Change
  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setpayLoad((values) => ({ ...values, [name]: value }));
  };

  const handleSelectChange = (value, name) => {
    setpayLoad((values) => ({ ...values, [name]: value }));
  };

  // Use to Submit Form
  const handleSubmit = (event: any) => {
    event.preventDefault();
    setLoadApi(true);
    setFormLoading(true);
  };

  // A custom hook to Process Form
  const result = useFormatApiRequest(
    () => sampleApiCall(payLoad),
    loadApi,
    () => {
      setLoadApi(false);
    },
    () => {
      processFormApi();
    }
  );

  // Process Api
  const processFormApi = async () => {
    if (result.httpState === "SUCCESS") {
      setFormLoading(false);
      setLoadAuth(true);
      if (onFormSuccess) {
        onFormSuccess();
      }
      // Handle Success Here
    } else if (result.httpState === "ERROR") {
      setFormLoading(false);
      if (onFormFailure) {
        onFormFailure(result.data?.response?.data?.message || result.errorMsg);
        alert(result.data?.response?.data?.message || result.errorMsg);
      }
      //Handle Error Here
    }
  };

  return (
    <div>
      <>
        <form onSubmit={handleSubmit}>
          {/* Forms Here */}
          <div className="w3-col w3-text-black w3-padding"></div>

          {/* Select Folder */}
          <div className="w3-col">
            <div className="w3-col w3-margin-bottom w3-padding">
              <div>
                <span>
                  Select Folder <span className="w3-text-red">*</span>
                </span>
              </div>
              <Select
                id="folderName"
                value={payLoad?.employee || ""}
                onChange={(val) => {
                  handleSelectChange(val, "folderName");
                }}
                showSearch
                className="w3-col w3-round-xlarge"
                style={{ width: "100%", height: "50px" }}
              >
                <Option value="folderName">Folder Name</Option>
              </Select>
            </div>
          </div>

          {/* File */}
          <div className="w3-col">
            <div className="w3-col w3-margin-bottom w3-padding">
              <div>
                <span>
                  Browse for File <span className="w3-text-red">*</span>
                </span>
              </div>
            </div>

            <div className="w3-col w3-margin-bottom w3-padding">
              <Dragger {...props}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
                <p className="ant-upload-hint">
                  Please Upload Files for the Employee.
                </p>
              </Dragger>
            </div>
          </div>
        </form>
      </>
    </div>
  );
};

export default HrEmployeeUploadFileForm;
