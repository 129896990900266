import { FolderOutlined } from "@ant-design/icons";
import { notification, Result, Button, Pagination, Modal } from "antd";
import { useState, useEffect } from "react";
import { sampleApiCall } from "../../../../apiservice/authService";
import useFormatApiRequest from "../../../../hooks/formatApiRequest";
import { appZIndex } from "../../../../utils/appconst";
import { ILoadState } from "../../../../utils/loading.utils.";
import "../hrEmployeeManagementComp.css";
import HrEmployeeDocumentFiles from "./hrEmployee-Document-Files";
type NotificationType = "success" | "info" | "warning" | "error";

type IHrEmployeeDocumentFolder = {
  externalFilter?: any;
  initialDefaultFilter?: any;
  hidePagination?: boolean;
};

export const HrEmployeeDocumentFolder: React.FC<IHrEmployeeDocumentFolder> = ({
  externalFilter,
  initialDefaultFilter,
  hidePagination = false,
}) => {
  const [foldersLoadState, setFoldersLoadState] =
    useState<ILoadState>("loading");
  const [loadFolderssData, setLoadFolderssData] = useState(true);
  const [foldersDefaultFilter, setFoldersDefaultFilter] = useState(
    initialDefaultFilter || { page: 1, perPage: 10 }
  );
  const [tableData, setTableData] = useState<any[]>([]);
  const [api, contextHolder] = notification.useNotification();

  // Pagination Constant/Variables
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const perPage = 10;

  // Modal To Open Files
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalIndex, setModalIndex] = useState<number>(0);

  const handleShowModal = (index) => {
    setModalIndex(index);
    setShowModal(true);
  };

  const handleCancelModal = () => {
    setShowModal(false);
  };

  // Use Effect to reload API when External Filter has changed
  useEffect(() => {
    if (externalFilter) {
      setCurrentPage(1);
      setFoldersDefaultFilter({
        ...foldersDefaultFilter,
        ...externalFilter,
        page: 1,
      });
      setLoadFolderssData(true);
      setFoldersLoadState("loading");
    }
  }, [externalFilter]);

  // A custom hook to Load All Folderss
  const FoldersDataResult = useFormatApiRequest(
    () => sampleApiCall(foldersDefaultFilter),
    loadFolderssData,
    () => {
      setLoadFolderssData(false);
    },
    () => {
      processFolderssResult();
    }
  );

  // Process The Current Folders Data Result
  const processFolderssResult = async () => {
    if (FoldersDataResult.httpState === "SUCCESS") {
      setTableData(FoldersDataResult.data?.data || [{}, {}, {}, {}, {}]);
      setTotalItems(FoldersDataResult.data?.meta?.total || 1);
      setFoldersLoadState("completed");
    } else if (FoldersDataResult.httpState === "ERROR") {
      setFoldersLoadState("error");
    } else if (FoldersDataResult.httpState === "LOADING") {
      setFoldersLoadState("loading");
    }
  };

  // Show Notification
  const openNotificationWithIcon = (
    type: NotificationType,
    message: string,
    description: string,
    background?: string
  ) => {
    api[type]({
      message,
      description,
      placement: "bottomRight",
      style: { background },
    });
  };

  // Use to Control Pagination On Change Event
  const onPageChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setFoldersDefaultFilter({
      ...foldersDefaultFilter,
      page: page,
      perPage: pageSize,
    });
    setLoadFolderssData(true);
  };

  return (
    <>
      {/* " The context is use to hold the notification from ant design" */}
      {contextHolder}

      {/* " Show Loading Error" */}
      {foldersLoadState === "error" && (
        <div className="w3-col w3-padding-bottom">
          <Result
            status="500"
            title="Error"
            subTitle="Sorry, something went wrong, it could be a network Related error"
            extra={
              <Button onClick={() => setLoadFolderssData(true)} type="primary">
                Reload
              </Button>
            }
          />
        </div>
      )}

      {/* " Show Loading Table" */}
      {foldersLoadState !== "error" && (
        <>
          <div className="w3-col w3-margin-top">
            <div className="grid gap-2 grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
              {tableData.map((items, index) => (
                <>
                  <div
                    onClick={() => {
                      handleShowModal(index);
                    }}
                    key={index}
                    className="p-8 bg-stone-200 rounded-lg shadow flex flex-col items-center justify-center text-black"
                  >
                    <p style={{ zoom: 2 }}>
                      <FolderOutlined />
                    </p>

                    <p className="pt-4"> Folder Name </p>
                  </div>
                </>
              ))}
            </div>
          </div>

          {!hidePagination && (
            <div className="w3-col w3-margin-top">
              <Pagination
                current={currentPage || 1}
                onChange={onPageChange}
                pageSize={perPage}
                total={totalItems}
              />
            </div>
          )}
        </>
      )}

      {/* Modal */}
      <Modal
        zIndex={appZIndex.modal}
        open={showModal}
        title={"Files In Folder"}
        onCancel={handleCancelModal}
        width={700}
        footer={[<p style={{ minHeight: "00px" }}></p>]}
      >
        <div style={{ maxHeight: "400px", overflow: "scroll" }}>
          <HrEmployeeDocumentFiles></HrEmployeeDocumentFiles>
        </div>
      </Modal>
    </>
  );
};

export default HrEmployeeDocumentFolder;
