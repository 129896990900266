import { Button, notification, Pagination, Result } from "antd";
import { useEffect, useState } from "react";
import { sampleApiCall } from "../../../../apiservice/authService";
import useFormatApiRequest from "../../../../hooks/formatApiRequest";
import { ILoadState } from "../../../../utils/loading.utils.";
import HrEmployeeDetailCardEmploymentHistory from "../hrEmployeeDetailCard-Employment-History";
import "../hrEmployeeManagementComp.css";
type NotificationType = "success" | "info" | "warning" | "error";

type IHrEmployeeHistoryList = {
  externalFilter?: any;
  initialDefaultFilter?: any;
  hidePagination?: boolean;
};

export const HrEmployeeHistoryList: React.FC<IHrEmployeeHistoryList> = ({
  externalFilter,
  initialDefaultFilter,
  hidePagination = false,
}) => {
  const [historyDataLoadState, setHistoryDataLoadState] =
    useState<ILoadState>("loading");
  const [loadHistoryDatasData, setLoadHistoryDatasData] = useState(true);
  const [historyDataDefaultFilter, setHistoryDataDefaultFilter] = useState(
    initialDefaultFilter || { page: 1, perPage: 10 }
  );
  const [tableData, setTableData] = useState<any[]>([]);
  const [api, contextHolder] = notification.useNotification();

  // Pagination Constant/Variables
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const perPage = 10;

  // Use Effect to reload API when External Filter has changed
  useEffect(() => {
    if (externalFilter) {
      setCurrentPage(1);
      setHistoryDataDefaultFilter({
        ...historyDataDefaultFilter,
        ...externalFilter,
        page: 1,
      });
      setLoadHistoryDatasData(true);
      setHistoryDataLoadState("loading");
    }
  }, [externalFilter]);

  // A custom hook to Load All HistoryDatas
  const HistoryDataDataResult = useFormatApiRequest(
    () => sampleApiCall(historyDataDefaultFilter),
    loadHistoryDatasData,
    () => {
      setLoadHistoryDatasData(false);
    },
    () => {
      processHistoryDatasResult();
    }
  );

  // Process The Current HistoryData Data Result
  const processHistoryDatasResult = async () => {
    if (HistoryDataDataResult.httpState === "SUCCESS") {
      setTableData(HistoryDataDataResult.data?.data || [{}, {}, {}, {}, {}]);
      setTotalItems(HistoryDataDataResult.data?.meta?.total || 1);
      setHistoryDataLoadState("completed");
    } else if (HistoryDataDataResult.httpState === "ERROR") {
      setHistoryDataLoadState("error");
    } else if (HistoryDataDataResult.httpState === "LOADING") {
      setHistoryDataLoadState("loading");
    }
  };

  // Show Notification
  const openNotificationWithIcon = (
    type: NotificationType,
    message: string,
    description: string,
    background?: string
  ) => {
    api[type]({
      message,
      description,
      placement: "bottomRight",
      style: { background },
    });
  };

  // Use to Control Pagination On Change Event
  const onPageChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setHistoryDataDefaultFilter({
      ...historyDataDefaultFilter,
      page: page,
      perPage: pageSize,
    });
    setLoadHistoryDatasData(true);
  };

  return (
    <>
      {/* " The context is use to hold the notification from ant design" */}
      {contextHolder}

      {/* " Show Loading Error" */}
      {historyDataLoadState === "error" && (
        <div className="w3-col w3-padding-bottom">
          <Result
            status="500"
            title="Error"
            subTitle="Sorry, something went wrong, it could be a network Related error"
            extra={
              <Button
                onClick={() => setLoadHistoryDatasData(true)}
                type="primary"
              >
                Reload
              </Button>
            }
          />
        </div>
      )}

      {/* " Show Loading Table" */}
      {historyDataLoadState !== "error" && (
        <>
          <div className="w3-col w3-margin-top">
            {tableData.map((item: any, index: number) => (
              <div className="w3-col" key={index}>
                <HrEmployeeDetailCardEmploymentHistory></HrEmployeeDetailCardEmploymentHistory>
              </div>
            ))}
          </div>

          {!hidePagination && (
            <div className="w3-col w3-margin-top">
              <Pagination
                current={currentPage || 1}
                onChange={onPageChange}
                pageSize={perPage}
                total={totalItems}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default HrEmployeeHistoryList;
