import { Button, notification, Pagination, Result, Tag } from "antd";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { sampleApiCall } from "../../../../apiservice/authService";
import useFormatApiRequest from "../../../../hooks/formatApiRequest";
import { ILoadState } from "../../../../utils/loading.utils.";
import "../HrCloudDashboard.css";
type NotificationType = "success" | "info" | "warning" | "error";

type IHrTopEmployeeList = {
  externalFilter?: any;
  initialDefaultFilter?: any;
  hidePagination?: boolean;
};

export const HrTopEmployeeList: React.FC<IHrTopEmployeeList> = ({
  externalFilter,
  initialDefaultFilter,
  hidePagination = false,
}) => {
  const [usersLoadState, setUsersLoadState] = useState<ILoadState>("loading");
  const [loadUserssData, setLoadUserssData] = useState(true);
  const [usersDefaultFilter, setUsersDefaultFilter] = useState(
    initialDefaultFilter || { page: 1, perPage: 10 }
  );
  const [tableData, setTableData] = useState<any[]>([]);
  const [api, contextHolder] = notification.useNotification();

  // Pagination Constant/Variables
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const perPage = 10;

  // Use Effect to reload API when External Filter has changed
  useEffect(() => {
    if (externalFilter) {
      setCurrentPage(1);
      setUsersDefaultFilter({
        ...usersDefaultFilter,
        ...externalFilter,
        page: 1,
      });
      setLoadUserssData(true);
      setUsersLoadState("loading");
    }
  }, [externalFilter]);

  // A custom hook to Load All Userss
  const UsersDataResult = useFormatApiRequest(
    () => sampleApiCall(usersDefaultFilter),
    loadUserssData,
    () => {
      setLoadUserssData(false);
    },
    () => {
      processUserssResult();
    }
  );

  // Process The Current Users Data Result
  const processUserssResult = async () => {
    if (UsersDataResult.httpState === "SUCCESS") {
      setTableData(
        UsersDataResult.data?.data || [{}, {}, {}, {}, {}, {},{}, {}, {}]
      );
      setTotalItems(UsersDataResult.data?.meta?.total || 1);
      setUsersLoadState("completed");
    } else if (UsersDataResult.httpState === "ERROR") {
      setUsersLoadState("error");
    } else if (UsersDataResult.httpState === "LOADING") {
      setUsersLoadState("loading");
    }
  };

  // Show Notification
  const openNotificationWithIcon = (
    type: NotificationType,
    message: string,
    description: string,
    background?: string
  ) => {
    api[type]({
      message,
      description,
      placement: "bottomRight",
      style: { background },
    });
  };

  // Use to Control Pagination On Change Event
  const onPageChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setUsersDefaultFilter({
      ...usersDefaultFilter,
      page: page,
      perPage: pageSize,
    });
    setLoadUserssData(true);
  };

  return (
    <>
      {/* " The context is use to hold the notification from ant design" */}
      {contextHolder}

      {/* " Show Loading Error" */}
      {usersLoadState === "error" && (
        <div className="w3-col w3-padding-bottom">
          <Result
            status="500"
            title="Error"
            subTitle="Sorry, something went wrong, it could be a network Related error"
            extra={
              <Button onClick={() => setLoadUserssData(true)} type="primary">
                Reload
              </Button>
            }
          />
        </div>
      )}

      {/* " Show Loading Table" */}
      {usersLoadState !== "error" && (
        <>
          <div className="grid content-start  border border-gray-300 shadow-lg p-2 rounded-xl">
            <h3 className="mb-4 font-sans text-lg font-semibold">
              Top Employee
            </h3>
            <div className="grid grid-cols-3 gap-2">
              {tableData.map((item: any[], index: number) => (
                <div key={index}>
                  <div className="border rounded-xl p-3">
                    <Link to={""}>
                      <p className="flex flex-col flex-wrap items-center justify-center">
                        <img
                          className="w3-circle"
                          style={{ width: "50px" }}
                          src={`${
                            process.env.PUBLIC_URL +
                            "/images/auth/profileSample.svg"
                          }`}
                          alt=""
                        />
                        <span className="font-sans text-xs text-gray-700">
                          Emmanuel Soligbo
                        </span>
                      </p>
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {!hidePagination && (
            <div className="w3-col w3-margin-top">
              <Pagination
                current={currentPage || 1}
                onChange={onPageChange}
                pageSize={perPage}
                total={totalItems}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default HrTopEmployeeList;
