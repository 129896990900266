import { Input, Modal } from "antd";
import { useState } from "react";
import { IAuthType } from "../../../../apiservice/authService.type";
import { useAppSelector } from "../../../../Redux/reduxCustomHook";
import { RootState } from "../../../../Redux/store";
import { appZIndex } from "../../../../utils/appconst";
import "../HrSettings.css";
import HrDepartmentForm from "../hrSettingsForm/HrDepartmentForm";
import HrDepartmentList from "./hrDepartmentList";

export const HrDepartmentListWrapper = () => {
  const [payLoadFilter, setpayLoadFilter] = useState<any>({});
  const [externalFilter, setExternalFilter] = useState<any>({});

  const [showModal, setShowModal] = useState<boolean>(false);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCancelModal = () => {
    setShowModal(false);
  };

  const authData: IAuthType = useAppSelector(
    (state: RootState) => state?.AuthData
  );

  // Use to collect Change
  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    // console.log(value)
    setpayLoadFilter((values) => ({ ...values, [name]: value }));
    if (name === "status") {
      setExternalFilter({ ...externalFilter, status: value });
    }
  };

  const handleButtonClick = (value) => {
    setpayLoadFilter((values) => ({ ...values }));
  };

  // Use to Update Filter
  const updateFilter = () => {
    setExternalFilter({ ...payLoadFilter });
  };

  return (
    <>
      <div className="w3-col">
        {/* Title */}
        <div className="w3-col w3-margin-top">
          <div
            className="w3-col l6 s6 m6"
            style={{ padding: "5px", paddingTop: "15px" }}
          >
            <h4>Department Settings</h4>
          </div>

          <div
            className="w3-col l6 s6 m6 w3-right-align"
            style={{ padding: "5px", paddingTop: "15px" }}
          >
            <button
              onClick={handleShowModal}
              className="w3-btn w3-round-large sitePrimaryButton"
            >
              Add Department
            </button>
          </div>
        </div>

        {/* Filters */}
        <div className="w3-col w3-margin-top">
          <div className="w3-col l9 s12 m12">
            <div className="w3-col" style={{ padding: "5px" }}>
              <Input
                name="searchstring"
                value={payLoadFilter?.searchstring || ""}
                onChange={handleInputChange}
                onBlur={updateFilter}
                className="w3-col w3-round-large inputSelect"
                style={{ height: "50px" }}
                placeholder="Enter a text to search"
              />
            </div>
          </div>

          <div className="w3-col l3 s12 m12">
            <div className="w3-col" style={{ padding: "5px" }}>
              <button className="w3-btn w3-col w3-round-large sitePrimaryBorderButton">
                Search
              </button>
            </div>
          </div>
        </div>

        <div className="w3-col w3-margin-top">
          <HrDepartmentList></HrDepartmentList>
        </div>
      </div>

      {/* Modal */}
      <Modal
        zIndex={appZIndex.modal}
        open={showModal}
        title={"Add Department"}
        onCancel={handleCancelModal}
        width={700}
        footer={[<p style={{ minHeight: "00px" }}></p>]}
      >
        <div style={{ maxHeight: "400px", overflow: "scroll" }}>
          <HrDepartmentForm></HrDepartmentForm>
        </div>
      </Modal>
    </>
  );
};

export default HrDepartmentListWrapper;
