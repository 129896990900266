import HRRecentRecruitmentList from "../hrEmployeeRecruitment/HrRecruitmentLists/hrEmployeeRecruitmentList";
import HRCloudDashboardMenu from "./HrClodDashboardMenu";
import { HrDashboardEmployeeChart } from "./HrCloudDashboard-Employee-Chart";
import "./HrCloudDashboard.css";
import { HrEmployeeCelebrationsList } from "./hrCloudList/hrEmployeeCelebrationList";
import HrTopEmployeeList from "./hrCloudList/hrTopEmployeeList";

export const HRCloudDashboardComp = () => {
  return (
    <>
      <div className="w3-container">
        <div className="w3-content">
          <div className="grid w3-margin-bottom">
            <h2 className="siteBorderPrimaryColor w3-xlarge">HR CLOUD</h2>
            <br />
            <p>
              Borem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
              vulputate libero et velit interdum, ac aliquet odio mattis.
              <br />
              Class aptent taciti sociosqu ad litora torquent per conubia
              nostra, per inceptos himenaeos.
            </p>
          </div>

          <div className="grid">
            <div className="grid mt-4">
              <HRCloudDashboardMenu></HRCloudDashboardMenu>
            </div>

            <div className="grid mt-8">
              <HrDashboardEmployeeChart></HrDashboardEmployeeChart>
            </div>

            <div className="grid mt-8 gap-2 grid-col-1 lg:grid-cols-2">
              <HrEmployeeCelebrationsList
                hidePagination
              ></HrEmployeeCelebrationsList>

              <HrTopEmployeeList hidePagination></HrTopEmployeeList>
            </div>

            {/* <div className="grid mt-8">
              <HRRecentRecruitmentList></HRRecentRecruitmentList>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default HRCloudDashboardComp;
