import { Button, notification, Pagination, Result, Tag } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { sampleApiCall } from "../../../../apiservice/authService";
import useFormatApiRequest from "../../../../hooks/formatApiRequest";
import { convertToShortDate } from "../../../../utils/date.utils";
import { ILoadState } from "../../../../utils/loading.utils.";
import "../HrEmployeeEngagement.css";
type NotificationType = "success" | "info" | "warning" | "error";

type IHrSuggestionList = {
  externalFilter?: any;
  initialDefaultFilter?: any;
  hidePagination?: boolean;
};

export const HrSuggestionList: React.FC<IHrSuggestionList> = ({
  externalFilter,
  initialDefaultFilter,
  hidePagination = true,
}) => {
  const [suggestionsLoadState, setSuggestionsLoadState] =
    useState<ILoadState>("loading");
  const [loadSuggestionsData, setLoadSuggestionsData] = useState(true);
  const [suggestionsDefaultFilter, setSuggestionsDefaultFilter] = useState(
    initialDefaultFilter || { page: 1, perPage: 10 }
  );
  const [tableData, setTableData] = useState<any[]>([]);
  const [api, contextHolder] = notification.useNotification();

  // Pagination Constant/Variables
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const perPage = 10;

  // Use Effect to reload API when External Filter has changed
  useEffect(() => {
    if (externalFilter) {
      setCurrentPage(1);
      setSuggestionsDefaultFilter({
        ...suggestionsDefaultFilter,
        ...externalFilter,
        page: 1,
      });
      setLoadSuggestionsData(true);
      setSuggestionsLoadState("loading");
    }
  }, [externalFilter]);

  // A custom hook to Load All Suggestions
  const SuggestionsDataResult = useFormatApiRequest(
    () => sampleApiCall(suggestionsDefaultFilter),
    loadSuggestionsData,
    () => {
      setLoadSuggestionsData(false);
    },
    () => {
      processSuggestionsResult();
    }
  );

  // Process The Current Suggestions Data Result
  const processSuggestionsResult = async () => {
    if (SuggestionsDataResult.httpState === "SUCCESS") {
      setTableData(SuggestionsDataResult.data?.data || [{}, {}, {}, {}]);
      setTotalItems(SuggestionsDataResult.data?.meta?.total || 1);
      setSuggestionsLoadState("completed");
    } else if (SuggestionsDataResult.httpState === "ERROR") {
      setSuggestionsLoadState("error");
    } else if (SuggestionsDataResult.httpState === "LOADING") {
      setSuggestionsLoadState("loading");
    }
  };

  // Show Notification
  const openNotificationWithIcon = (
    type: NotificationType,
    message: string,
    description: string,
    background?: string
  ) => {
    api[type]({
      message,
      description,
      placement: "bottomRight",
      style: { background },
    });
  };

  // Table Configuration
  const columns: ColumnsType<any> = [
    {
      title: "Employee",
      dataIndex: "employee",
      key: "employee",
      render: (text, records, index) => <span>{"James Manager"}</span>,
    },
    {
      title: "Narative",
      dataIndex: "narative",
      key: "narative",
      render: (text, records, index) => <span>{"Narative"}</span>,
    },

    {
      title: "FeedBack",
      dataIndex: "feedBack",
      key: "feedBack",
      render: (text, records, index) => <span>{"FeedBack"}</span>,
    },
    {
      title: "Date Review",
      dataIndex: "dateReviewed",
      key: "dateReviewed",
      render: (text) => <span>{convertToShortDate("12-02-2024")}</span>,
    },
    {
      title: "Status",
      dataIndex: "interviewStatus",
      key: "interviewStatus",
      render: (text, records, index) => (
        <Tag className="tagText" color={"green"}>
          {"Done"}
        </Tag>
      ),
    },
    {
      title: "Date",
      dataIndex: "dateModified",
      key: "dateModified",
      render: (text) => <span>{convertToShortDate("12-02-2024")}</span>,
    },
  ];

  // Use to Control Pagination On Change Event
  const onPageChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setSuggestionsDefaultFilter({
      ...suggestionsDefaultFilter,
      page: page,
      perPage: pageSize,
    });
    setLoadSuggestionsData(true);
  };

  return (
    <>
      {/* " The context is use to hold the notification from ant design" */}
      {contextHolder}

      {/* " Show Loading Error" */}
      {suggestionsLoadState === "error" && (
        <div className="w3-col w3-padding-bottom">
          <Result
            status="500"
            title="Error"
            subTitle="Sorry, something went wrong, it could be a network Related error"
            extra={
              <Button
                onClick={() => setLoadSuggestionsData(true)}
                type="primary"
              >
                Reload
              </Button>
            }
          />
        </div>
      )}

      {/* " Show Loading Table" */}
      {suggestionsLoadState !== "error" && (
        <>
          <div className="w3-col">
            <div className="w3-border w3-padding w3-round-xlarge">
              <Table
                className="w3-round-large"
                loading={suggestionsLoadState === "loading"}
                rowKey="id"
                size="small"
                columns={columns}
                dataSource={tableData}
                scroll={{ x: 950 }}
                pagination={{ hideOnSinglePage: true }}
              />
            </div>
          </div>

          {!hidePagination && (
            <div className="w3-col w3-margin-top">
              <Pagination
                current={currentPage || 1}
                onChange={onPageChange}
                pageSize={perPage}
                total={totalItems}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default HrSuggestionList;
