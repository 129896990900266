import {
  EnvironmentOutlined,
  MailOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import { Modal } from "antd";
import { useState } from "react";
import { appZIndex } from "../../../utils/appconst";
import HrEmployeeEditBankInfoForm from "./hrEmployeeforms/HrEmployee-Edit-Bank-Info-form";
import HrEmployeeEditContactInfoForm from "./hrEmployeeforms/HrEmployee-Edit-Contact-Info-form";
import HrEmployeeEditEmergencyContactInfo from "./hrEmployeeforms/HrEmployee-Edit-Emergency-Info-form";
import HrEmployeeEditGeneralInfoForm from "./hrEmployeeforms/HrEmployee-Edit-General-Info-form";
import HrEmployeeEditNextOfKinContactInfo from "./hrEmployeeforms/HrEmployee-Edit-NextOfKin-Info-form";
import "./hrEmployeeManagementComp.css";

type IHrEmployeeDetailCardPersonalInfo = {
  employeeManagementData?: any | null;
  refreshPage?: () => void;
};

type IModalType = {
  title: string;
  content: JSX.Element;
};

export const HrEmployeeDetailCardPersonalInfo: React.FC<
  IHrEmployeeDetailCardPersonalInfo
> = ({ employeeManagementData, refreshPage }) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalIndex, setModalIndex] = useState<number>(0);

  const handleShowModal = (index) => {
    setModalIndex(index);
    setShowModal(true);
  };

  const handleCancelModal = () => {
    setShowModal(false);
  };

  const modals: IModalType[] = [
    {
      title: "Update Contact Information",
      content: (
        <>
          <HrEmployeeEditContactInfoForm></HrEmployeeEditContactInfoForm>
        </>
      ),
    },
    {
      title: "Update General Information",
      content: (
        <>
          <HrEmployeeEditGeneralInfoForm></HrEmployeeEditGeneralInfoForm>
        </>
      ),
    },
    {
      title: "Update Emergency Contact",
      content: (
        <>
          <HrEmployeeEditEmergencyContactInfo></HrEmployeeEditEmergencyContactInfo>
        </>
      ),
    },
    {
      title: "Update Next of Kin",
      content: (
        <>
          <HrEmployeeEditNextOfKinContactInfo></HrEmployeeEditNextOfKinContactInfo>
        </>
      ),
    },
    {
      title: "Update Banking Details",
      content: (
        <>
          <HrEmployeeEditBankInfoForm></HrEmployeeEditBankInfoForm>
        </>
      ),
    },
  ];

  return (
    <>
      <div className="w3-col" style={{ minHeight: "100px" }}>
        {/* Contact Info */}
        <div className="grid mb-12 pb-8 border-b border-black">
          {/* Section Header */}
          <div className="grid grid-cols-2 gap-1">
            <div>
              <span className="text-black text-md font-sans font-medium">
                Contact Information
              </span>
            </div>
            <div className="flex items-end justify-end">
              <button
                onClick={() => {
                  handleShowModal(0);
                }}
                className="text-black text-xs border border-black rounded-md font-sans h-8 px-2 py-1.5 pe-2"
              >
                Edit
              </button>
            </div>
          </div>

          {/* Section Body */}
          <div className="grid grid-cols-1 gap-1 gap-y-4  mt-4 md:grid-cols-2 lg:grid-cols-3">
            {/* Phone */}
            <div>
              <span className="text-black text-xs font-sans">
                <PhoneOutlined /> +234 70 1122 33445
              </span>
            </div>

            {/* Email */}
            <div>
              <span className="text-black text-xs font-sans">
                <MailOutlined /> textemail@eamil.com
              </span>
            </div>

            {/* Location */}
            <div>
              <span className="text-black text-xs font-sans">
                <EnvironmentOutlined /> Warri, Delta State
              </span>
            </div>
          </div>
        </div>

        {/* General Information  */}
        <div className="grid mb-12 pb-8 border-b border-black">
          {/* Section Header */}
          <div className="grid grid-cols-2 gap-1">
            <div>
              <span className="text-black text-md font-sans font-medium">
                General Information
              </span>
            </div>
            <div className="flex items-end justify-end">
              <button
                onClick={() => {
                  handleShowModal(1);
                }}
                className="text-black text-xs border border-black rounded-md font-sans h-8 px-2 py-1.5 pe-2"
              >
                Edit
              </button>
            </div>
          </div>

          {/* Section Body */}
          <div className="grid grid-cols-1 gap-1 gap-y-8 mt-4 md:grid-cols-2 lg:grid-cols-3">
            {/* Joining Date  */}
            <div>
              <div>
                <div className="pb-4 text-black text-sm font-sans font-medium">
                  Joining Date
                </div>
                <div className="text-black text-xs font-sans">01, Jan 2024</div>
              </div>
            </div>

            {/* Department */}
            <div>
              <div>
                <div className="pb-4 text-black text-sm font-sans font-medium">
                  Department
                </div>
                <div className="text-black text-xs font-sans"> IT Support </div>
              </div>
            </div>

            {/* Employment Type */}
            <div>
              <div>
                <div className="pb-4 text-black text-sm font-sans font-medium">
                  Employment Type
                </div>
                <div className="text-black text-xs font-sans"> Remote </div>
              </div>
            </div>

            {/* Merital Status  */}
            <div>
              <div>
                <div className="pb-4 text-black text-sm font-sans font-medium">
                  Marital Status
                </div>
                <div className="text-black text-xs font-sans"> Married </div>
              </div>
            </div>

            {/* BirthDay */}
            <div>
              <div>
                <div className="pb-4 text-black text-sm font-sans font-medium">
                  BirthDay
                </div>
                <div className="text-black text-xs font-sans"> 01 Jan 1970</div>
              </div>
            </div>
          </div>
        </div>

        {/* Emergency Contact  */}
        <div className="grid mb-12 pb-8 border-b border-black">
          {/* Section Header */}
          <div className="grid grid-cols-2 gap-1">
            <div>
              <span className="text-black text-md font-sans font-medium">
                Emergency Contact
              </span>
            </div>
            <div className="flex items-end justify-end">
              <button
                onClick={() => {
                  handleShowModal(2);
                }}
                className="text-black text-xs border border-black rounded-md font-sans h-8 px-2 py-1.5 pe-2"
              >
                Edit
              </button>
            </div>
          </div>

          {/* Section Body */}
          <div className="grid grid-cols-1 gap-1 gap-y-8 mt-4 md:grid-cols-2 lg:grid-cols-3">
            {/* Name  */}
            <div>
              <div>
                <div className="pb-4 text-black text-sm font-sans font-medium">
                  Name
                </div>
                <div className="text-black text-xs font-sans">
                  David Beckham
                </div>
              </div>
            </div>

            {/* RelationShip */}
            <div>
              <div>
                <div className="pb-4 text-black text-sm font-sans font-medium">
                  Relationship
                </div>
                <div className="text-black text-xs font-sans"> Brother </div>
              </div>
            </div>

            {/* Phone Number */}
            <div>
              <div>
                <div className="pb-4 text-black text-sm font-sans font-medium">
                  Phone Number
                </div>
                <div className="text-black text-xs font-sans">
                  {" "}
                  + 234 89122 19929{" "}
                </div>
              </div>
            </div>

            {/*  Email Address  */}
            <div>
              <div>
                <div className="pb-4 text-black text-sm font-sans font-medium">
                  Email Address
                </div>
                <div className="text-black text-xs font-sans">
                  {" "}
                  davidbeckam@email.com{" "}
                </div>
              </div>
            </div>

            {/* Location  */}
            <div>
              <div>
                <div className="pb-4 text-black text-sm font-sans font-medium">
                  Location
                </div>
                <div className="text-black text-xs font-sans">
                  {" "}
                  Manchester (UK){" "}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Next of Kin */}
        <div className="grid mb-12 pb-8 border-b border-black">
          {/* Section Header */}
          <div className="grid grid-cols-2 gap-1">
            <div>
              <span className="text-black text-md font-sans font-medium">
                Next of Kin
              </span>
            </div>
            <div className="flex items-end justify-end">
              <button
                onClick={() => {
                  handleShowModal(3);
                }}
                className="text-black text-xs border border-black rounded-md font-sans h-8 px-2 py-1.5 pe-2"
              >
                Edit
              </button>
            </div>
          </div>

          {/* Section Body */}
          <div className="grid grid-cols-1 gap-1 gap-y-8 mt-4 md:grid-cols-2 lg:grid-cols-3">
            {/* Name  */}
            <div>
              <div>
                <div className="pb-4 text-black text-sm font-sans font-medium">
                  Name
                </div>
                <div className="text-black text-xs font-sans">
                  David Beckham
                </div>
              </div>
            </div>

            {/* RelationShip */}
            <div>
              <div>
                <div className="pb-4 text-black text-sm font-sans font-medium">
                  Relationship
                </div>
                <div className="text-black text-xs font-sans"> Brother </div>
              </div>
            </div>

            {/* Phone Number */}
            <div>
              <div>
                <div className="pb-4 text-black text-sm font-sans font-medium">
                  Phone Number
                </div>
                <div className="text-black text-xs font-sans">
                  {" "}
                  + 234 89122 19929{" "}
                </div>
              </div>
            </div>

            {/*  Email Address  */}
            <div>
              <div>
                <div className="pb-4 text-black text-sm font-sans font-medium">
                  Email Address
                </div>
                <div className="text-black text-xs font-sans">
                  {" "}
                  davidbeckam@email.com{" "}
                </div>
              </div>
            </div>

            {/* Location  */}
            <div>
              <div>
                <div className="pb-4 text-black text-sm font-sans font-medium">
                  Location
                </div>
                <div className="text-black text-xs font-sans">
                  {" "}
                  Manchester (UK){" "}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Banking Details */}
        <div className="grid mb-12 pb-8 border-b border-black">
          {/* Section Header */}
          <div className="grid grid-cols-2 gap-1">
            <div>
              <span className="text-black text-md font-sans font-medium">
                Banking Details
              </span>
            </div>
            <div className="flex items-end justify-end">
              <button
                onClick={() => {
                  handleShowModal(4);
                }}
                className="text-black text-xs border border-black rounded-md font-sans h-8 px-2 py-1.5 pe-2"
              >
                Edit
              </button>
            </div>
          </div>

          {/* Section Body */}
          <div className="grid grid-cols-1 gap-1 gap-y-8 mt-4 md:grid-cols-2 lg:grid-cols-3">
            {/* Name  */}
            <div>
              <div>
                <div className="pb-4 text-black text-sm font-sans font-medium">
                  Account Name
                </div>
                <div className="text-black text-xs font-sans">James John</div>
              </div>
            </div>

            {/*  Account Number  */}
            <div>
              <div>
                <div className="pb-4 text-black text-sm font-sans font-medium">
                  Account Number
                </div>
                <div className="text-black text-xs font-sans">
                  {" "}
                  09100100101{" "}
                </div>
              </div>
            </div>

            {/* Bank Name */}
            <div>
              <div>
                <div className="pb-4 text-black text-sm font-sans font-medium">
                  Bank Name
                </div>
                <div className="text-black text-xs font-sans">
                  United Bank for Africa
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      <Modal
        zIndex={appZIndex.modal}
        open={showModal}
        title={modals[modalIndex].title}
        onCancel={handleCancelModal}
        width={700}
        footer={[<p style={{ minHeight: "00px" }}></p>]}
      >
        <div style={{ maxHeight: "400px", overflow: "scroll" }}>
          {modals[modalIndex].content}
        </div>
      </Modal>
    </>
  );
};

export default HrEmployeeDetailCardPersonalInfo;
